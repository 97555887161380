import { InjectionToken } from '@angular/core';
import { Configuration } from '@azure/msal-browser/dist/config/Configuration';

export interface activeDirectoryIdpConfiguration {
  appClientId: string;
  apiClientId: string;
  tenantId: string;
  endPoint: string;
  relativeScope: string;
  relativeRedirectUrl: string;
  relativeSilentRenewUrl: string;
}

export interface appInsightsConfiguration {
  applicationInsightsInstrumentationKey: string;
}



export interface namesConfiguration {
	signUpSignIn: string,
	resetPassword: string,
	editProfile: string,
	signIn: string
}

export interface userFlowConfiguration {
	authority: string
}

export interface authorityConfiguration {
	signUpSign: userFlowConfiguration,
	signIn: userFlowConfiguration,
	resetPassword: userFlowConfiguration,
	editProfile: userFlowConfiguration,
}

export interface b2cPoliciesConfiguration {
	names: namesConfiguration,
	authorities: authorityConfiguration,
	authorityDomain: string
}



export interface appConfiguration {
  production: boolean;
  appRootUrl: string;
  apiRootUrl: string;
  resourceAppID: string;
  authAuthority: string;
  knownAuthority: string;
  azureBlobStorageURL: string;
  appInsightsConfiguration: appInsightsConfiguration;
  activeDirectoryIdpConfiguration: activeDirectoryIdpConfiguration;
  b2cPolicies: b2cPoliciesConfiguration,
  msalConfig: Configuration
}

export const APP_CONFIG = new InjectionToken<appConfiguration>('APP_CONFIG');
