import { Injectable } from '@angular/core';
import { KeyValue } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CommonFunctionsService {
  public getLocalTime = (d: Date): Date => {
    let someDateString = (d.getMonth()+1) + '/' +
      d.getDate() + '/' +
      d.getFullYear() + ' ' +
      d.getHours() + ":" +
      d.getMinutes() + ":" +
      d.getSeconds() + ' UTC';

    return new Date(Date.parse(someDateString));
  }

  // methods to sort maps
  public valueAscOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return a.value.localeCompare(b.value);
  };
  keyDescOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return a.key > b.key ? -1 : b.key > a.key ? 1 : 0;
  };

  // method to check if a certain value is an integer
  public CheckIfInteger = (value: any): boolean => {
    if (parseFloat(value) == parseInt(value) && !isNaN(value)) {
      // I can have spacespacespace1 - which is 1 and validators pases but
      // spacespacespace doesn't - which is what i wanted.
      // 1space2 doesn't pass - good
      // of course, when saving data you do another parseInt.
      return true;
    } else {
      return false;
    }
  };

  public get BrowserIsChrome(): boolean {
    return window.navigator.userAgent.toLowerCase().includes('chrome');
  };

  public getUSStateAbbreviations(): Array<string> {
    return [
      'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
      'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
      'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
      'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
      'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
    ];
  };

  public BuildLocationString = (
    city: string,
    state: string
  ): string | undefined => {
    var loc;
    if (city) {
      loc = city;
      if (state) loc = loc + ', ' + state;
    } else {
      if (state) loc = state;
    }
    return loc;
  };

  public findBracketedGuidInString = (
    stringToSearch: string
  ): string | null => {
    var re = /\[([a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12})\]/i;

    // the RegEx will match the first occurrence of the pattern
    var match = re.exec(stringToSearch);

    // result is an array containing:
    // [0] the entire string that was matched by our RegEx
    // [1] the first (only) group within our match, specified by the
    // () within our pattern, which contains the GUID value
    return match ? match[1] : null;
  };

  public findBracketLessGuidInString = (
    stringToSearch: string
  ): string | null => {
    var re = /([a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12})/i;

    // the RegEx will match the first occurrence of the pattern
    var match = re.exec(stringToSearch);

    // result is an array containing:
    // [0] the entire string that was matched by our RegEx
    // [1] the first (only) group within our match, specified by the
    // () within our pattern, which contains the GUID value
    return match ? match[1] : null;
  };

  public SortOn = function (arr: any, prop: any, reverse: any, numeric: any) {
    // Ensure there's a property
    if (!prop || !arr) {
      return arr;
    }

    // Set up sort function
    var sort_by = function (field: any, rev: any, primer: any) {
      // Return the required a,b function
      return function (a: any, b: any) {
        // Reset a, b to the field
        (a = primer(a[field])), (b = primer(b[field]));

        // Do actual sorting, reverse as needed
        return (a < b ? -1 : a > b ? 1 : 0) * (rev ? -1 : 1);
      };
    };

    // Distinguish between numeric and string to prevent 100's from coming before smaller
    // e.g.
    // 1
    // 20
    // 3
    // 4000
    // 50

    if (numeric) {
      // Do sort "in place" with sort_by function
      arr.sort(
        sort_by(prop, reverse, function (a: any) {
          // - Force value to a string.
          // - Replace any non numeric characters.
          // - Parse as float to allow 0.02 values.
          return parseFloat(String(a).replace(/[^0-9.-]+/g, ''));
        })
      );
    } else {
      // Do sort "in place" with sort_by function
      arr.sort(
        sort_by(prop, reverse, function (a: any) {
          // - Force value to string.
          return String(a).toUpperCase();
        })
      );
    }
  };

  // Calculate the difference of two dates in total days
  public diffDays = function (d1: any, d2: any): number {
    var ndays;
    var tv1 = d1.valueOf();
    var tv2 = d2.valueOf();

    ndays = (tv2 - tv1) / 1000 / 86400;
    ndays = Math.round(ndays - 0.5);
    return ndays;
  };

  public randomInteger(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  public randomNumber(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }
}
