import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveEnd, Router} from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { appConfiguration, APP_CONFIG } from '../configuration/app-configuration';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Injectable({providedIn: 'root'})
export class ApplicationInsightsService {
  private routerSubscription: Subscription;
  private appInsights: ApplicationInsights | null = null;

  constructor(
    @Inject(APP_CONFIG) public appConfig: appConfiguration,
    private router: Router
  ) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: appConfig.appInsightsConfiguration.applicationInsightsInstrumentationKey,
        enableCorsCorrelation: true,
        enableAutoRouteTracking: true
      },
    });
    this.appInsights.loadAppInsights();
    this.routerSubscription = this.router.events
      .pipe(filter((event): event is ResolveEnd => event instanceof ResolveEnd))
      .subscribe((event) => {
        const activatedComponent = this.getActivatedComponent(event.state.root);
        if (activatedComponent) {
          this.logPageView(
            `${activatedComponent.name} ${this.getRouteTemplate(
              event.state.root
            )}`,
            event.urlAfterRedirects
          );
        }
      });
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights!.trackException({ exception: exception, severityLevel: severityLevel });
  }

  setUserId(userId: string) {
    if (this.appInsights) {
      this.appInsights.setAuthenticatedUserContext(userId);
    }
  }

  clearUserId() {
    if (this.appInsights) {
      this.appInsights.clearAuthenticatedUserContext();
    }
  }

  logPageView(name?: string, uri?: string) {
    if (this.appInsights) {
      this.appInsights.trackPageView({ name, uri });
    }
  }


  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }
}
