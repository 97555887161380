import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureService } from './feature.service';

@Directive({
    selector: '[hasFeatures]'
})
export class FeatureDirective {

    constructor(
      private _featureService: FeatureService,
      private readonly viewRef: ViewContainerRef,
      private readonly templateRef: TemplateRef<any>
    ) { }

    @Input()
    public set hasFeatures(features: string[]) {
        if (!features?.length) {
            this.viewRef.createEmbeddedView(this.templateRef);
            return;
        }

        const hasAllFeatures = this._featureService.hasAllFeaturesEnabled(...features);
        if (hasAllFeatures) {
            this.viewRef.createEmbeddedView(this.templateRef);
        } else {
            this.viewRef.clear();
        }
    }
}
