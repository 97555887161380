export interface Task {
    orderID: number;
    address: string;
    shipping: string;
  }
  export interface OrderData {
    date: string;
    address: string;
    orderNumber: string;
  }
  
  export const tasks: Task[] = [
    {
      orderID: 62153641,
      address: '8354 Lookout St. Spring Valley, NY 10977',
      shipping: 'Standard 12/23',
    },
    {
      orderID: 62853635,
      address: '258 Victoria Street Bronx, NY 10465',
      shipping: 'Fixed 12/23 9:00 AM',
    },
    {
      orderID: 62123014,
      address: '83 Addison St. Spring Valley, NY 10977',
      shipping: 'Standard 12/23',
    },
    {
      orderID: 70620112,
      address: '456 Oak Lane Anytown, NY 12345',
      shipping: 'Express 13/23',
    },
    {
      orderID: 71143024,
      address: '789 Maple Ave. Somewhere, NY 67890',
      shipping: 'Priority 12/23 2:00 PM',
    },
    {
      orderID: 83128011,
      address: '101 Pine Rd. Nowhere, NY 54321',
      shipping: 'Standard 12/23',
    },
    {
      orderID: 82123014,
      address: '202 Elm Blvd. Anywhere, NY 98765',
      shipping: 'Fixed 12/23 11:30 AM',
    },
  ];