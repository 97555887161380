import { Component, OnInit } from '@angular/core';
//import { TranslateService } from '@ngx-translate/core';
//import { CookieService } from 'ngx-cookie-service';
//import { AppConstants } from 'src/app/app-constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  //private readonly appConstants: typeof AppConstants = AppConstants;

  constructor(
    //private cookieService: CookieService
  ) {}

  ngOnInit(): void {}

  // public OnChangeLanguageClick = (targetLanguage: string) => {
  //   this.translateService.use(targetLanguage);
  //   this.cookieService.set(
  //     this.appConstants.LOCALSTORAGE_LANGUAGESELECTED,
  //     targetLanguage,
  //     {
  //       expires: 90,
  //     }
  //   );
  // };
}
