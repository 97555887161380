import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AppComponent } from 'src/app/app.component';
import { AdminApproveInspectionModalComponent } from '../admin-approve-inspection-modal/admin-approve-inspection-modal.component';
import { AdminFailInspectionModalComponent } from '../admin-fail-inspection-modal/admin-fail-inspection-modal.component';
import { ApproveInspectionModalComponent } from '../approve-inspection-modal/approve-inspection-modal.component';
import { ApproveVisitModalComponent } from '../approve-visit-modal/approve-visit-modal.component';
import { CancelInspectionModalComponent } from '../cancel-inspection-modal/cancel-inspection-modal.component';
import { FailInspectionModalComponent } from '../fail-inspection-modal/fail-inspection-modal.component';
import { UpdateInspectionModalComponent } from '../update-inspection-modal/update-inspection-modal.component';
import { DenyVisitModalComponent } from '../deny-visit-modal/deny-visit-modal.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SearchInspectionViewModel } from 'src/app/core/services/application-api.service';

@Component({
  selector: 'app-inspection-search-grid',
  templateUrl: './inspection-search-grid.component.html',
  styleUrls: ['./inspection-search-grid.component.scss']
})
export class InspectionSearchGridComponent {
  @Input() public inspections: Array<SearchInspectionViewModel> = new Array<SearchInspectionViewModel>();
  public inspectionsTable: MatTableDataSource<SearchInspectionViewModel> = new MatTableDataSource<SearchInspectionViewModel>();
  @Input() public userRole: string | null | undefined = "";
  public displayedColumns: string[] = ['id','status','company','tradePartnerName','date', 'time', 'timeEnd', 'trade','type','specificSiteLocation','requestedBy','visitOptions','actions'];
  public inspectionCompanies = new Array();
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  constructor(private appComponent: AppComponent,
              private dialog: MatDialog
  )
  {
  }

  ngOnChanges(): void {
    this.userRole = this.userRole;
    console.log("InspectionSearchGridComponent userRole: " + this.userRole);
    this.inspectionsTable = new MatTableDataSource<SearchInspectionViewModel>(this.inspections);
    this.inspectionsTable.paginator = this.paginator;
    this.inspectionsTable.sort = this.sort; 

  }

  public CanApproveVisit(status: string): boolean
  {
    //Cannor approve visit if status is not in Requested
    if (status != "Requested")
      return false;
    else
    {
      //Only Inspector can approve or SuperUser can do anything
      if (this.userRole == "SuperUser" || this.userRole == "Inspector")
        return true;
    }
    return false;
  }

  public CanDenyVisit(status: string): boolean
  {
    if (status != "Requested" && status != "Scheduled")
      return false;
    else{
      if (this.userRole == "SuperUser" || this.userRole == "Inspector" || this.userRole == "QA" || this.userRole == "Owner Rep")
        return true;
    }

    return false;
  }

  public CanUpdateInspection(status: string): boolean
  {
    if(status != "Requested" && status != "Reschedule Requested")
    {
      return false;
    }
    else
    {
      if(this.userRole == "QA" || this.userRole == "Owner Rep" || this.userRole == "SuperUser")
        return true;
    }

    return false;
  }

  public CanCancelVisit(status: string): boolean
  {
    if(status == "Passed" || status == "Failed" || status == "Canceled")
      return false;
    else
    {
      if(this.userRole == "Inspector" || this.userRole == "QA" || this.userRole == "Owner Rep" || this.userRole == "SuperUser" )
        return true;
    }

    return false;
  }

  public CanApproveInspection(status: string): boolean
  {
    if (status != "Scheduled")
      return false;
    else
    {
      if(this.userRole == "Inspector" || this.userRole == "Owner Rep" || this.userRole == "SuperUser")
      {
        return true;
      }
    }

    return false;
  }

  public CanFailInspection(status: string): boolean
  {
    if (status != "Scheduled")
      return false;
    else
    {
      if(this.userRole == "Inspector" || this.userRole == "Owner Rep" || this.userRole == "SuperUser")
      {
        return true;
      }
    }

    return false;
  }

  public CanAdminApproveInspection(status: string): boolean
  {
    if (status == "Failed" && this.userRole == "SuperUser")
    {
      return true;
    }
    return false;
  }
  
  public CanAdminFailInspection(status: string): boolean
  {
    if (status == "Passed" && (this.userRole == "SuperUser" || this.userRole == "Inspector"))
    {
      return true;
    }
    return false;
  }

  public ApproveInspectionPopup(inspection: Inspection)
  {
    let dialogRef = this.dialog.open(ApproveInspectionModalComponent, {
      data:{
        id: inspection.id,
        inspection: inspection
      }
    });
  }

  public FailInspectionPopup(inspection: Inspection)
  {
    let dialogRef = this.dialog.open(FailInspectionModalComponent, {
      data:{
        id: inspection.id,
        inspection: inspection
      }
    });
  }
  
  public UpdateInspectionPopup(id: number,
                               inspectionCompanyId: number,
                               date: string,
                               time: string,
                               dateStart: string,
                               dateEnd: string)
  {
    let dialogRef = this.dialog.open(UpdateInspectionModalComponent, {
      data:{
        id: id,
        inspectionCompanies: this.inspectionCompanies,
        inspectionCompanyId: inspectionCompanyId,
        dateStart: new Date(dateStart),
        dateEnd: new Date(dateEnd),
        projectId: 1
      }
    });
  }
  
  public CancelInspectionPopup(inspection: Inspection)
  {
    let dialogRef = this.dialog.open(CancelInspectionModalComponent, {
      data:{
        id: inspection.id,
        inspection: inspection
      }
    });
  }
  
  public AdminApproveInspectionPopup(inspection: Inspection)
  {
    let dialogRef = this.dialog.open(AdminApproveInspectionModalComponent, {
      data:{
        id: inspection.id,
        inspection: inspection
      }
    });
  }
  
  public AdminFailInspectionPopup(inspection: Inspection)
  {
    let dialogRef = this.dialog.open(AdminFailInspectionModalComponent, {
      data:{
        id: inspection.id,
        inspection: inspection
      }
    });
  }
  
  public ApproveVisitPopup(inspection: Inspection)
  {
    let dialogRef = this.dialog.open(ApproveVisitModalComponent, {
      data:{
        id: inspection.id,
        inspection: inspection
      }
    });
  }

  public DenyVisitPopup(id: number)
  {
    let dialogRef = this.dialog.open(DenyVisitModalComponent, {
      data:{
        id: id
      }
    });
  }
}

export class Inspection{
  id? = 0;
  status? = "";
  company? = "";
  tradePartnerName? = "";
  inspectionCompanyId? = 0;
  date?= "";
  time? = "";
  trade? = "";
  type? = "";
  description = "";
  specificSiteLocation? = "";
  requestedBy? = "";
}
