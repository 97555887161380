import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType, AuthenticationResult, IdTokenClaims } from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { APP_CONFIG, appConfiguration } from 'src/app/core/configuration/app-configuration';
import { ApplicationApiService, DeliveryScheduleOptionsViewModel, PostDeliveryRequestBody, ProjectSearchViewModel, SearchDeliveryOptionsViewModel, SiteLocationViewModel, TradePartnerViewModel, UserSearchViewModel } from 'src/app/core/services/application-api.service';
import { CommonFunctionsService } from 'src/app/core/services/common-functions';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string,
  tfp?: string,
  city?: string,
  given_name?: string,
  jobTitle?: string,
  oid?: string
};

@Component({
  selector: 'app-add-delivery',
  templateUrl: './add-delivery.component.html',
  styleUrls: ['./add-delivery.component.scss']
})

export class AddDeliveryComponent implements OnInit {
  public companies: TradePartnerViewModel[] | undefined;
  public siteLocations: SiteLocationViewModel[] | undefined;
  public projects: ProjectSearchViewModel[] | undefined;
  public timeSlots = [{id: 15, name: "15 min"},{id: 30, name: "30 min"},{id: 45, name: "45 min"},{id: 60, name: "1 hr"},
                      {id: 75, name: "75 min"},{id: 90, name: "90 min"},{id: 105, name: "105 min"},{id: 120, name: "2 hr"}];
  //public userObjectId : string | undefined = "";
  private readonly _destroying$ = new Subject<void>();
  private allDeliveryOptions = {} as DeliveryScheduleOptionsViewModel;
  public isLoading: boolean = false;
  public submitdisabled: boolean = false;
  public userModel = {} as UserSearchViewModel;
  public reactiveForm: FormGroup = new FormGroup({
    deliveryDate: new FormControl('',Validators.required),
    deliveryTime: new FormControl('12:00 PM',Validators.required),
    deliveryTimeSlot: new FormControl("30",Validators.required),
    project: new FormControl('',Validators.required),
    company: new FormControl({ value: '', disabled: true },Validators.required),
    siteLocation: new FormControl({ value: '', disabled: true },Validators.required),
    description: new FormControl({ value: '', disabled: true },Validators.required)
  });

  public browserName = "";
  public isMapHidden: boolean = true;

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private applicationApiService: ApplicationApiService,
    private snackBar: MatSnackBar,
    private appComponent: AppComponent,
    private commonFunctions: CommonFunctionsService,
    @Inject(APP_CONFIG) public appConfig: appConfiguration,
  ){}

  ngOnInit(){
    this.isLoading = true;
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
            || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
            || msg.eventType === EventType.SSO_SILENT_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        let payload = result.payload as AuthenticationResult;
        let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

        console.log("Payload Access Token: " + payload.idToken);
        console.log("Object Id: " + idtoken.oid);
        //this.userObjectId = idtoken.oid;
      });

      this.applicationApiService.apiUserGetUser().subscribe(
        {
          next:(model: UserSearchViewModel) => {
            this.userModel = model;
            this.GetScheduleDeliveryOptions(this.userModel.objectId?.toString());
          },
          error: (error: string) => {
            this.ShowSnackBar('Authentication error', 'Ok');
            this.appComponent.logout();
            this.isLoading = false;
          }
        });

      this.browserName = window.navigator.userAgent;
  }

  get f()
  {
    return this.reactiveForm.controls;
  }

  private GetScheduleDeliveryOptions(id: string | undefined) {
    this.applicationApiService.apiDeliveryScheduleDeliveryOptions(id)
      .subscribe((deliveryOptions: DeliveryScheduleOptionsViewModel) => {
        this.allDeliveryOptions = deliveryOptions;
        this.projects = deliveryOptions.projects!;
        this.isLoading = false;
      });
  }

  public OnProjectChange(id: string) {
    // enable controls
    this.reactiveForm.controls['company'].enable();
    this.reactiveForm.controls['siteLocation'].enable();
    this.reactiveForm.controls['description'].enable();

    // filter trades and site locations by project selected
    this.companies = this.allDeliveryOptions.companies?.filter(i => i.projectId == +id);
    this.siteLocations = this.allDeliveryOptions.siteLocations?.filter(i => i.projectId == +id);

    this.isMapHidden = false;
  }

  public OnSubmit(form: FormGroup) {
    this.submitdisabled = true;
    let theContactId = this.userModel.objectId?.toString();

    // build delivery date with time
    const day = this.reactiveForm.value.deliveryDate.getDate();
    const month = this.reactiveForm.value.deliveryDate.getMonth();
    const year = this.reactiveForm.value.deliveryDate.getFullYear();
    let hour = Number(this.reactiveForm.value.deliveryTime.substr(0, this.reactiveForm.value.deliveryTime.indexOf(':')));
    const minute = Number(this.reactiveForm.value.deliveryTime.substr(this.reactiveForm.value.deliveryTime.indexOf(':') + 1,2));
    if (this.reactiveForm.value.deliveryTime.indexOf('PM') > 0 && hour != 12)
      hour = hour + 12;
    const deliveryDateTime = new Date(year, month, day, hour, minute);
    let localDateTime = this.commonFunctions.getLocalTime(deliveryDateTime);

    let body: PostDeliveryRequestBody = {
      siteLocationId: this.reactiveForm.value.siteLocation,
      deliveryDate: localDateTime,
      projectId: this.reactiveForm.value.project,
      tradePartnerId: this.reactiveForm.value.company,
      userId: (theContactId !== undefined)?theContactId:'',
      name: "name",
      description: this.reactiveForm.value.description,
      timeSlot: this.reactiveForm.value.deliveryTimeSlot
    };

    console.log('body', body);

     this.applicationApiService.apiDeliveryPostDelivery(body)
      .subscribe((numberAdded: number) => {
         console.log("Number Added: " + numberAdded);
         window.location.href = "./delivery/search";
       });
  }

  ShowSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
        duration: 3000
    });
  }
}
