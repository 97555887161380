<form [formGroup]="formGroup" (ngSubmit)="OnSubmit(formGroup)">
  <h3 mat-dialog-title><strong>Reschedule Delivery?</strong></h3>
  <mat-dialog-content>
    <p><span style="font-weight: 500;">Delivery ID:</span> {{data.id}}</p>
    <div class="col-12">
      <mat-form-field>
        <mat-label>Delivery Date</mat-label>
        <input matInput formControlName="deliveryDate" [matDatepicker]="DeliveryDate">
        <mat-datepicker-toggle matIconSuffix [for]="DeliveryDate"></mat-datepicker-toggle>
        <mat-datepicker #DeliveryDate></mat-datepicker>
          <mat-error *ngIf="f['deliveryDate'].errors?.['required']">
            Delivery Date is Required
          </mat-error>
      </mat-form-field>
    </div>
    <div class="col-12 pt-2">
      <ngx-mat-timepicker-field syle="width:100%" color="warn" [format]="12" formControlName="deliveryTime"></ngx-mat-timepicker-field>
      <mat-error *ngIf="f['deliveryTime'].errors?.['required']">
        Delivery Time is Required
      </mat-error>
    </div>
    <div class="col-12 pt-2">
      <mat-form-field>
        <mat-label>Time Slot</mat-label>
        <mat-select formControlName="deliveryTimeSlot">
          <mat-option *ngFor="let timeSlot of timeSlots" value="{{timeSlot.id}}">{{timeSlot.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="f['deliveryTimeSlot'].errors?.['required']">
          Time Slot is Required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-12 pt-2">
      <mat-progress-bar *ngIf="isSaving" mode="indeterminate" style="width:100%" color="warn"></mat-progress-bar>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="p-3">
    <button mat-raised-button type="button" (click)="OnCancelClick()">Cancel</button>&nbsp;&nbsp;
    <button mat-raised-button color="warn" type="submit">Reschedule</button>
  </mat-dialog-actions>
</form>
