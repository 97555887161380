import { Component, Inject, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApplicationApiService } from 'src/app/core/services/application-api.service';

@Component({
  selector: 'app-deny-visit-modal',
  templateUrl: './deny-visit-modal.component.html',
  styleUrls: ['./deny-visit-modal.component.scss']
})
export class DenyVisitModalComponent {

  public id = 0;
  public isSaving: boolean = false;
  public timeSlot: number = 15;
  public timeSlots = [{id: 15, name: "15 min"},{id: 30, name: "30 min"},{id: 45, name: "45 min"},{id: 60, name: "1 hr"},
                      {id: 75, name: "75 min"},{id: 90, name: "90 min"},{id: 105, name: "105 min"},{id: 120, name: "2 hr"}];
  public formGroup: FormGroup = new FormGroup({
    inspectionDate: new FormControl('', Validators.required),
    inspectionTime: new FormControl('12:00 PM', Validators.required),
    inspectionTimeSlot: new FormControl(15,Validators.required)
  });
  

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<DenyVisitModalComponent>,
    private applicationApiService: ApplicationApiService
  ){

  }

  ngOnInit(): void {
    this.id = this.data.id;
  }
  
  public OnCancelClick()
  {
     this.ref.close();
  }

  get f()
  {
    return this.formGroup.controls;
  }

  public OnUpdateInspection(formGroup: FormGroup)
  {
    console.log("Date: " + formGroup.value['inspectionDate']);
    let inspectionDate = new Date(formGroup.value['inspectionDate']);
    let inspectionTime = formGroup.value['inspectionTime'];
    let inspectionDateTime = this.combineDateTime(inspectionDate, inspectionTime);
    this.isSaving = true;
    this.applicationApiService.apiInspectionUpdateInspection(this.id,undefined,inspectionDateTime,inspectionDateTime,2,undefined) //TO DO: remove magic number = 3
    .subscribe((result: number) => {
      this.isSaving = false;
    });

    this.ref.close();
  }

  private combineDateTime(date: any, time: string)
  {
    // build delivery date with time
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    let hour = Number(time.substr(0, time.indexOf(':')));
    const minute = Number(time.substr(time.indexOf(':') + 1,2));
    if (time.indexOf('PM') > 0 && hour != 12)
      hour = hour + 12;
    const deliveryDateTime = new Date(year, month, day, hour, minute);
    return deliveryDateTime;
  }
}
