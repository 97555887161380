import { Component, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { CreateFormGroupArgs, EditMode, Resource, SchedulerEvent, SchedulerComponent, Group, DragEndEvent, SlotDragEndEvent, EditEvent, DateChangeEvent, ResizeEvent, RemoveEvent, } from '@progress/kendo-angular-scheduler';
import { DragTargetDragStartEvent, DragTargetDragEndEvent, DropTargetEvent, DragTargetContainerDirective} from '@progress/kendo-angular-utils';
import { Task, OrderData, tasks } from './delivery-list/tasks';
import { ApplicationApiService, DeliverySearchViewModel, EventsViewModel, ProjectSearchViewModel, SiteLocationViewModel } from 'src/app/core/services/application-api.service';
import { MatDialog } from '@angular/material/dialog';
import { UpdateDeliveryModalComponent } from '../../delivery/search-delivery/update-delivery-modal/update-delivery-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';

export class resourceData{
  text = "";
  value = 0;
  color = "";
}

type ExtendedSchedulerEvent = SchedulerEvent & {
  statusId? : number,
  statusName? : string,
};

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['demo-styles.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CalendarComponent implements OnInit {
  @ViewChild('dragWrapper', { read: DragTargetContainerDirective })
  public dragTargetContainer!: DragTargetContainerDirective;

  public events: ExtendedSchedulerEvent[] = Array<ExtendedSchedulerEvent>();
  public resources: Resource[] = [
    {
      name: 'Rooms',
      data: [
        { text: 'Some Site Location', value: 1, color: '#fc0303'},
      ],
      field: 'roomId',
      valueField: 'value',
      textField: 'text',
      colorField: 'color',
    },
  ];
  public group: Group = { resources: ["Rooms"], orientation: "horizontal"};
  public selectedDate: Date = new Date();
  public formGroup!: FormGroup;

  public tasks: Task[] = tasks;

  public isDropValid = false;
  public currentData: OrderData = {
    date: 'No Data Available!',
    address: '',
    orderNumber: '',
  };

  public calendarWidth: number = 1200;

  
  public projectId: number | undefined;
  public projects= {} as ProjectSearchViewModel[];
  public siteLocationId: number | undefined;
  public siteLocations= {} as SiteLocationViewModel[];

  constructor(private formBuilder: FormBuilder,
              private renderer: Renderer2,
              private applicationApiService: ApplicationApiService,
              private snackBar: MatSnackBar,
              private matDialog: MatDialog,) {
                this.createFormGroup = this.createFormGroup.bind(this);
  
  }

  ngOnInit(): void {
    this.GetEvents("some guid",undefined, undefined);
    this.GetProjects();
  }

  private GetEvents(userId: string | undefined,
    projectId: number | undefined,
    siteLocationId: number | undefined)
  {
  this.applicationApiService.apiEventsGetDeliveryEvents(userId,projectId, siteLocationId).subscribe(
  {
    next:(theEvents:EventsViewModel[]) => {
          let baseData: any[] = [];
          theEvents.forEach((obj) => {
          let singleData: any = {
          "TaskID": obj.id,
          "OwnerID": 0,
          "Title": obj.title,
          "Description": obj.description,
          "StartTimezone": null,
          "Start": obj.start?.toString(),
          "End": obj.end?.toString(),
          "EndTimezone": null,
          "RecurrenceRule": null,
          "RecurrenceID": null,
          "RecurrenceException": null,
          "IsAllDay": false,
          "SiteLocation" : obj.siteLocation,
          "SiteLocationId" : obj.siteLocationId,
          "Status" : obj.status,
          "StatusId" : obj.statusId
        };

        baseData.push(singleData);
      });

      let currentYear = new Date().getFullYear();
      let parseAdjust = (eventDate: string): Date => {
        let date = new Date(eventDate);
        date.setFullYear(currentYear);
        return date;
      };

      let sampleData2 = baseData.map(dataItem => (
        <ExtendedSchedulerEvent> {
          id: dataItem.TaskID,
          start: parseAdjust(dataItem.Start),
          startTimezone: dataItem.startTimezone,
          end: parseAdjust(dataItem.End),
          endTimezone: dataItem.endTimezone,
          isAllDay: dataItem.IsAllDay,
          title: dataItem.Title,
          description: dataItem.Description,
          recurrenceRule: dataItem.RecurrenceRule,
          recurrenceId: dataItem.RecurrenceID,
          recurrenceException: dataItem.RecurrenceException,
          roomId: dataItem.SiteLocationId,
          ownerID: dataItem.OwnerID,
          statusId: dataItem.StatusId,
          statusName: dataItem.Status
        }
      ));

      this.events = sampleData2;

      //load sitelocations into kendo
      var siteLocations = theEvents.map(sl => sl.siteLocation);
      const myList = Array.from(siteLocations);
      const unique = [...new Set(myList)];

      var array: resourceData[] = [];
      let counter = 0;
      unique.forEach(element => {
      var set = theEvents.filter(a => a.siteLocation == element);
      let ele = new resourceData();
      if(set[0].siteLocation?.toString() !== undefined)
      {
        ele.text = set[0].siteLocation?.toString();
      }
      if(set[0].siteLocationId !== undefined)
      {
        ele.value = set[0].siteLocationId;
      }
      ele.color = "#fc0303"; //this.colors[counter];
      counter++;
      if(counter > 6)
      {
      counter = 0;
      }
      array.push(ele);
      });

      this.resources = [
        {
          name: "Rooms",
          data: array,
          field: "roomId",
          valueField: "value",
          textField: "text",
          colorField: "color",
        },
      ];
    //end load sitelocations into kendo
    
    },
      error: (error: string) => {
        //this.ShowSnackBar('Error getting calendar', 'Ok');
        //this.isLoading = false;
      }
    });
    }


  public ProjectChanged(event: any)
  {
    this.GetSiteLocations(event.value);
    this.GetEvents("-", event.value, undefined);
  }

  public SiteLocationChanged(event: any)
  {
    this.GetEvents("-", this.projectId, event.value);
  }

  private GetSiteLocations(projectId: number | undefined)
  {
    if (projectId !== undefined)
    {
      this.siteLocations = [{id: undefined, name: ''}] as SiteLocationViewModel[];
      
      this.applicationApiService.apiSiteLocationGetSiteLocations(projectId)
        .subscribe((models: SiteLocationViewModel[]) => {
          models.forEach((element) => {
            this.siteLocations.push(element);
          });
        });
    }
  }

  private GetProjects()
  {
    this.projects = [{id: undefined, name: ''}] as ProjectSearchViewModel[];

    this.applicationApiService.apiProjectGetProjects().subscribe((model: ProjectSearchViewModel[]) => {
      model.forEach((element) => {
        this.projects.push(element);
        console.log("model element: " + element);
      });
    });
  }

  public onResizeEnd(ev: any): void {
    let timeSlot = (ev.end.getTime() - ev.start.getTime())/60000;
    let hour = ev.start.toString().split(" ")[4].split(":")[0];
    let siteLocation = undefined;

    this.applicationApiService.apiDeliveryUpdateDeliveryStatus(ev.dataItem.id,
                                                               ev.dataItem.statusId,
                                                               siteLocation,
                                                               ev.start,
                                                               +hour,
                                                               timeSlot).subscribe(() => {

      }
    );
    
    //update via API here
  }

  public onDragEnd(ev: DragEndEvent): void {
    let timeSlot = (ev.end.getTime() - ev.start.getTime())/60000;
    let hour = ev.start.toString().split(" ")[4].split(":")[0];
    let siteLocation = +ev.resources.roomId;
    
    this.applicationApiService.apiDeliveryUpdateDeliveryStatus(ev.dataItem.id,
                                                               ev.dataItem.statusId,
                                                               siteLocation,
                                                               ev.start,
                                                               +hour,
                                                               timeSlot).subscribe(() => {

      }
    );
    
  


    let event: EventsViewModel = ev.dataItem as EventsViewModel;

    //update via API here
  
  }

  public onChangeView(ev: number): void {

    if(ev == 0) //day view
    {
      this.calendarWidth = 200 * this.resources[0].data.length;
    }

    if (ev == 1) //week view
    {
      this.calendarWidth = 100 * 7 * this.resources[0].data.length;

    }
  }

  public onEventDblClick(ev: any) {
    console.log("double click");
    //add popup modal here

    let model = {} as DeliverySearchViewModel;
    let array = Object.getOwnPropertyNames(ev.event);
    array.forEach((ele) => {console.log(ele)});
    model.deliveryDate = new Date(ev.event.start);
    model.id = ev.event.id;

    this.UpdateDeliveryPopup(model);
  }

  public onSave(): void {
    if (this.isDropValid) {
      const itemId = this.currentData.orderNumber;

      this.deleteTaskByOrderID(+itemId.slice(1));
      this.dragTargetContainer.notify();
    }
  }

  public onDelete(ev: RemoveEvent): void 
  {
    ev.preventDefault();
  }

  public UpdateDeliveryPopup(delivery: DeliverySearchViewModel) {
    let dialogRef = this.matDialog.open(UpdateDeliveryModalComponent, {
      minHeight: '325px',
      width: '325px',
      data: delivery
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result.event == 'Update') {
        this.ShowSnackBar('Delivery Updated Successfully', 'Ok');
      }
    });
  }

  public ShowSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000
    });
  }

  private updateDelivery()
  {

  }




  private addHighlight(target: HTMLElement) {
    target && this.renderer.addClass(target, 'timeSlotHighlight');
  }
  private removeHighlight(target: HTMLElement) {
    target && this.renderer.removeClass(target, 'timeSlotHighlight');
  }

  private deleteTaskByOrderID = (orderIDToDelete: number): void => {
    this.tasks = this.tasks.filter((task) => task.orderID !== orderIDToDelete);
  };

  private extractHintContext(dragOuterText: string) {
    const lines: string[] = dragOuterText.split('\n');

    const filteredLines: string[] = lines.filter((line) => line.trim() !== '');

    const extractedInfo: OrderData = {
      date: filteredLines[1],
      address: filteredLines[2],
      orderNumber: filteredLines[0],
    };
    this.currentData = extractedInfo;
  }

  private getNextId(): number {
    const len = this.events.length;

    return len === 0 ? 1 : this.events[this.events.length - 1].id + 1;
  }

  public createFormGroup(args: CreateFormGroupArgs): FormGroup {
    
    const dataItem = args.dataItem;
    const isOccurrence = args.mode === EditMode.Occurrence;
    const exceptions = isOccurrence ? [] : dataItem.recurrenceExceptions;
    console.log("createFormGroup");

    this.formGroup = this.formBuilder.group(
      {
      },
      {
        validators: null //this.startEndValidator,
      }
    );

    return this.formGroup;
  }
}