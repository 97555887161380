<div id="deliverysearch">
  <div [hidden]="!isLoading">
    <div class="d-flex flex-column align-items-center">
      <div class="p-2">
          <h2>Loading Options...</h2>
          <div>Please wait...</div>
      </div>
      <div class="p-2">
          <mat-spinner></mat-spinner>
      </div>
    </div>
  </div>
  <div [hidden]="isLoading">
    <mat-card class="p-3">
      <h2 style="color:#c34d28;">Search Deliveries&nbsp;<mat-icon style="vertical-align: sub; margin-left: 5px; margin-right: 5px; font-size: 26px">local_shipping</mat-icon></h2>
      <form [formGroup]="reactiveForm" (ngSubmit)="OnSubmit(reactiveForm)">
        <div class="row pt-3">
          <div class="col-3">
            <mat-form-field style="width: 100%;">
              <mat-label>Project</mat-label>
              <mat-select formControlName="project">
                <mat-option *ngFor="let project of projects" [value]="project.id">
                  {{project.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field style="width: 100%;">
              <mat-label>Trade Partner</mat-label>
              <mat-select formControlName="tradePartner">
                <mat-option *ngFor="let tradePartner of tradePartners" [value]="tradePartner.id">
                  {{tradePartner.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-3">
            <mat-form-field style="width: 100%;">
              <mat-label>Status</mat-label>
              <mat-select formControlName="status">
                <mat-option *ngFor="let status of statuses" value={{status.id}}>
                  {{status.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field style="width: 100%;">
              <mat-label>From (MM/DD/YYYY)</mat-label>
              <input matInput [matDatepicker]="pickerFrom" formControlName="startTime">
              <mat-datepicker-toggle matIconSuffix [for]="pickerFrom"></mat-datepicker-toggle>
              <mat-datepicker #pickerFrom></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field style="width: 100%;">
              <mat-label>To (MM/DD/YYYY)</mat-label>
              <input matInput [matDatepicker]="pickerTo" formControlName="endTime">
              <mat-datepicker-toggle matIconSuffix [for]="pickerTo"></mat-datepicker-toggle>
              <mat-datepicker #pickerTo></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-end">
            <button mat-raised-button type="submit" [disabled]="isSearching" color="warn">Search Deliveries<mat-icon iconPositionEnd>search</mat-icon></button>&nbsp;&nbsp;
            <button mat-raised-button color="warn" (click)="ExportData(deliveries)">Export to Excel<mat-icon iconPositionEnd>file_download</mat-icon></button>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-12">
            <mat-progress-bar *ngIf="isSearching" mode="indeterminate" style="width:100%" color="warn"></mat-progress-bar>
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col-12 my-2">
          <app-delivery-search-grid [deliveries]="deliveries" [userRole]="userRole"></app-delivery-search-grid>
        </div>
      </div>
    </mat-card>
  </div>
</div>
