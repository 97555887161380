<div [hidden]="!isLoading">
  <div class="d-flex flex-column align-items-center">
    <div class="p-2">
        <h2>Loading Options...</h2>
        <div>Please wait...</div>
    </div>
    <div class="p-2">
        <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
<div [hidden]="isLoading">
  <mat-card class="p-3">
    <h2 style="color:#00275d;">Search Inspections&nbsp;<mat-icon style="vertical-align: sub; margin-left: 5px; margin-right: 5px;">search</mat-icon></h2>
    <form [formGroup]="reactiveForm" (ngSubmit)="OnSubmit(reactiveForm)">
      <div class="container">
        <div class="row pt-3">
          <div class="col-3">
            <mat-form-field style="width:100%">
              <mat-label>Project</mat-label>
              <mat-select formControlName="projectId" [(value)]="projectId">
                  <mat-option *ngFor="let project of projects" value={{project.id}}>
                      {{project.name}}
                  </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field style="width:100%">
              <mat-label>Trade Partner</mat-label>
              <mat-select formControlName="companyId">
                <mat-option *ngFor="let company of companies" value={{company.id}}>
                  {{company.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field style="width:100%">
              <mat-label>Inspection Type</mat-label>
              <mat-select formControlName="inspectionTypeId">
                <mat-option *ngFor="let inspectionType of inspectionTypes" value={{inspectionType.id}}>
                    {{inspectionType.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field style="width:100%">
              <mat-label>Project Area</mat-label>
              <mat-select formControlName="siteLocation">
                <mat-option *ngFor="let projectArea of projectAreas" value={{projectArea.id}}>
                    {{projectArea.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <mat-form-field style="width:100%">
              <mat-label>Status</mat-label>
              <mat-select formControlName="statusId">
                  <mat-option *ngFor="let status of statuses" value={{status.id}}>
                      {{status.name}}
                  </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field style="width: 100%;">
              <mat-label>From (MM/DD/YYYY)</mat-label>
              <input matInput [matDatepicker]="pickerFrom" formControlName="fromTime">
              <mat-datepicker-toggle matIconSuffix [for]="pickerFrom"></mat-datepicker-toggle>
              <mat-datepicker #pickerFrom></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field style="width: 100%;">
              <mat-label>To (MM/DD/YYYY)</mat-label>
              <input matInput [matDatepicker]="pickerTo" formControlName="toTime">
              <mat-datepicker-toggle matIconSuffix [for]="pickerTo"></mat-datepicker-toggle>
              <mat-datepicker #pickerTo></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-end">
            <button mat-raised-button type="submit" [disabled]="isSearching" color="primary">Search Inspections<mat-icon iconPositionEnd>search</mat-icon></button>&nbsp;&nbsp;
            <button mat-raised-button color="primary" (click)="ExportData(inspections.data)">Export to Excel<mat-icon iconPositionEnd>file_download</mat-icon></button>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-12">
            <mat-progress-bar *ngIf="isSearching" mode="indeterminate" style="width:100%"></mat-progress-bar>
          </div>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-12 my-3">
          <app-inspection-search-grid [inspections]="inspectionsList" [userRole]="userRole"></app-inspection-search-grid>
      </div>
    </div>
  </mat-card>
</div>
