
<table mat-table matSort matSortDisableClear [dataSource]="inspectionsTable" class="mat-elevation-z4" matSortActive="id" matSortDirection="desc">
    <!-- id -->
    <ng-container matColumnDef="id">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Id</th>
        <td mat-cell *matCellDef="let r">{{r.id}}</td>
    </ng-container>

    <!-- status -->
    <ng-container matColumnDef="status">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let r">{{r.status}}</td>
    </ng-container>

    <!-- company -->
    <ng-container matColumnDef="tradePartnerName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Trade Partner</th>
        <td mat-cell *matCellDef="let r">{{r.tradePartnerName}}</td>
    </ng-container>

    <!-- company -->
    <ng-container matColumnDef="company">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Inspection Agency</th>
        <td mat-cell *matCellDef="let r">{{r.company}}</td>
    </ng-container>

    <!-- date -->
    <ng-container matColumnDef="date">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let r">{{r.inspectionDateTimeStart | date:'MM/dd/yyyy'}}</td>
    </ng-container>

    <!-- time -->
    <ng-container matColumnDef="time">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Start</th>
        <td mat-cell *matCellDef="let r">{{r.inspectionDateTimeStart | date: "hh:mm a" }}</td>
    </ng-container>

    <!-- time -->
    <ng-container matColumnDef="timeEnd">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>End</th>
        <td mat-cell *matCellDef="let r">{{r.inspectionDateTimeEnd | date: "hh:mm a" }}</td>
    </ng-container>

    <!-- trade -->
    <ng-container matColumnDef="trade">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Division</th>
        <td mat-cell *matCellDef="let r">{{r.trade}}</td>
    </ng-container>

    <!-- type -->
    <ng-container matColumnDef="type">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let r">{{r.type}}</td>
    </ng-container>

    <!-- type -->
    <ng-container matColumnDef="specificSiteLocation">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Specific Site Location</th>
        <td mat-cell *matCellDef="let r">{{r.siteLocation}}</td>
    </ng-container>

    <!-- Requested By-->
    <ng-container matColumnDef="requestedBy">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Requested By</th>
        <td mat-cell *matCellDef="let r">{{r.requestedBy}}</td>
    </ng-container>

    <!-- visitOptions -->
    <ng-container  matColumnDef="visitOptions">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Approve Visit</th>
        <td mat-cell *matCellDef="let r">
            <mat-icon *ngIf="CanApproveVisit(r.status)" matTooltip="Approve Visit" (click)="ApproveVisitPopup(r)" class="green spaceRight">calendar_today</mat-icon>
            <mat-icon *ngIf="CanDenyVisit(r.status)" matTooltip="Reschedule Visit" (click)="DenyVisitPopup(r.id)" class="purple">cached</mat-icon>
        </td>
    </ng-container>

    <!-- actions -->
    <ng-container  matColumnDef="actions">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let r">
            <mat-icon *ngIf="CanApproveInspection(r.status)" matTooltip="Approve Inspection" (click)="ApproveInspectionPopup(r)" class="green spaceRight">thumb_up</mat-icon>
            <mat-icon *ngIf="CanFailInspection(r.status)" matTooltip="Fail Inspection" (click)="FailInspectionPopup(r)" class="red spaceRight">thumb_down</mat-icon>
            <mat-icon *ngIf="CanUpdateInspection(r.status)" matTooltip="Update Inspection" (click)="UpdateInspectionPopup(r.id, r.inspectionCompanyId, r.inspectionDateTimeStart, r.inspectionDateTimeStart, r.inspectionDateTimeStart, r.inspectionDateTimeEnd)" class="blue spaceRight">edit</mat-icon>
            <mat-icon *ngIf="CanCancelVisit(r.status)" matTooltip="Cancel Inspection" (click)="CancelInspectionPopup(r)" class="red spaceRight">cancel</mat-icon>
            <mat-icon *ngIf="CanAdminApproveInspection(r.status)" matTooltip="Inspector Correction Approve Inspection" (click)="AdminApproveInspectionPopup(r)" class="green spaceRight">done_outline</mat-icon>
            <mat-icon *ngIf="CanAdminFailInspection(r.status)"  matTooltip="Inspector Correction Fail Inspection" (click)="AdminFailInspectionPopup(r)" class="red">highlight_off</mat-icon>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let r; columns: displayedColumns"></tr>
</table>
<mat-paginator class="mat-elevation-z4"
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 25, 50, 100]"
      showFirstLastButtons="">
</mat-paginator>
