import { Component, Input, OnInit } from '@angular/core';
import { SchedulerEvent } from '@progress/kendo-angular-scheduler/types/scheduler-event';
import { ApplicationApiService, EventsViewModel, ProjectSearchViewModel, SiteLocationViewModel, UserSearchViewModel } from 'src/app/core/services/application-api.service';
//import { sampleData } from '../events-utc';
import { MatSnackBar } from '@angular/material/snack-bar';

export class resourceData{
  text = "";
  value = 0;
  color = "";
}

@Component({
  selector: 'app-inspectioncalendar',
  templateUrl: './inspectioncalendar.component.html',
  styleUrls: ['./inspectioncalendar.component.scss']
})
export class InspectioncalendarComponent implements OnInit {
  //Inputs
  @Input() public userObjectId: string = "";

  public selectedDate: Date = new Date ();
  public events: SchedulerEvent[] = new Array<SchedulerEvent>;
  public colors: string[] = ["#ff6666", "#3366ff", "#66ff33", "#ffff33", "#d633ff", "#85adad"];
  public isLoading: boolean = false;
  public resources: any[] = [
    {
      name: "Rooms",
      data: [
        { text: "Area 51", value: 1, color: "#6eb3fa" },
        { text: "Area A", value: 2, color: "#f58a8a" },
      ],
      field: "roomId",
      valueField: "value",
      textField: "text",
      colorField: "color",
    },
  ];
  public group: any = {
    resources: ["Rooms"],
    orientation: "horizontal",
  };

  public projectId: number | undefined;
  public projects= {} as ProjectSearchViewModel[];
  public siteLocationId: number | undefined;
  public siteLocations= {} as SiteLocationViewModel[];

  constructor(private applicationApiService: ApplicationApiService,
    private snackBar: MatSnackBar){}
  
  ngOnInit() {
    this.isLoading = true;
    this.applicationApiService.apiUserGetUser().subscribe(
      {
        next:(model: UserSearchViewModel) => {
          this.GetEvents(this.userObjectId, this.projectId, this.siteLocationId);
          this.GetProjects();
          this.isLoading = false;
        },
        error: (error: string) => {
          this.ShowSnackBar('Authentication error', 'Ok');
          this.isLoading = false;
        }
      });

      console.log("UserAgent: " + window.navigator.userAgent);
  }

  public ProjectChanged(event: any)
  {
    this.GetSiteLocations(event.value);
    this.GetEvents(this.userObjectId, event.value, undefined);
  }

  public SiteLocationChanged(event: any)
  {
    this.GetEvents(this.userObjectId, this.projectId, event.value);
  }

  private GetSiteLocations(projectId: number | undefined)
  {
    if (projectId !== undefined)
    {
      this.siteLocations = [{id: undefined, name: ''}] as SiteLocationViewModel[];
      
      this.applicationApiService.apiSiteLocationGetSiteLocations(projectId)
        .subscribe((models: SiteLocationViewModel[]) => {
          models.forEach((element) => {
            this.siteLocations.push(element);
          });
        });
    }
  }

  private GetEvents(userId: string | undefined,
    projectId: number | undefined,
    siteLocationId: number | undefined)
  {
  this.applicationApiService.apiEventsGetInspectionEvents(userId,projectId, siteLocationId).subscribe(
  {
    next:(theEvents:EventsViewModel[]) => {
          let baseData: any[] = [];
          theEvents.forEach((obj) => {
          let singleData: any = {
          "TaskID": obj.id,
          "OwnerID": 0,
          "Title": obj.title,
          "Description": obj.description,
          "StartTimezone": null,
          "Start": obj.start?.toString(),
          "End": obj.end?.toString(),
          "EndTimezone": null,
          "RecurrenceRule": null,
          "RecurrenceID": null,
          "RecurrenceException": null,
          "IsAllDay": false,
          "SiteLocation" : obj.siteLocation,
          "SiteLocationId" : obj.siteLocationId
        };

        baseData.push(singleData);
      });

      let currentYear = new Date().getFullYear();
      let parseAdjust = (eventDate: string): Date => {
        let date = new Date(eventDate);
        date.setFullYear(currentYear);
        return date;
      };

      let sampleData2 = baseData.map(dataItem => (
        <SchedulerEvent> {
          id: dataItem.TaskID,
          start: parseAdjust(dataItem.Start),
          startTimezone: dataItem.startTimezone,
          end: parseAdjust(dataItem.End),
          endTimezone: dataItem.endTimezone,
          isAllDay: dataItem.IsAllDay,
          title: dataItem.Title,
          description: dataItem.Description,
          recurrenceRule: dataItem.RecurrenceRule,
          recurrenceId: dataItem.RecurrenceID,
          recurrenceException: dataItem.RecurrenceException,
          roomId: dataItem.SiteLocationId,
          ownerID: dataItem.OwnerID
        }
      ));

      this.events = sampleData2;

      //load sitelocations into kendo
      var siteLocations = theEvents.map(sl => sl.siteLocation);
      const myList = Array.from(siteLocations);
      const unique = [...new Set(myList)];

      var array: resourceData[] = [];
      let counter = 0;
      unique.forEach(element => {
      var set = theEvents.filter(a => a.siteLocation == element);
      let ele = new resourceData();
      if(set[0].siteLocation?.toString() !== undefined)
      {
        ele.text = set[0].siteLocation?.toString();
      }
      if(set[0].siteLocationId !== undefined)
      {
        ele.value = set[0].siteLocationId;
      }
      ele.color = this.colors[counter];
      counter++;
      if(counter > 6)
      {
      counter = 0;
      }
      array.push(ele);
      });

      this.resources = [
        {
          name: "Rooms",
          data: array,
          field: "roomId",
          valueField: "value",
          textField: "text",
          colorField: "color",
        },
      ];
    //end load sitelocations into kendo

    },
      error: (error: string) => {
        this.ShowSnackBar('Error getting inspection calendar', 'Ok');
        this.isLoading = false;
      }
    });
    }

    private GetProjects()
  {
    this.projects = [{id: undefined, name: ''}] as ProjectSearchViewModel[];

    this.applicationApiService.apiProjectGetProjects().subscribe((model: ProjectSearchViewModel[]) => {
      model.forEach((element) => {
        this.projects.push(element);
        console.log("model element: " + element);
      });
    });
  }

  private ShowSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
        duration: 3000
    });
  }
}
