import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationApiService } from '../../services/application-api.service';
import { B2cDirective } from './b2c.directive';
import { B2cService } from './b2c.service';
import { B2cGuard } from './b2-c.guard';

@NgModule({
    declarations: [B2cDirective],
    exports: [B2cDirective],
    imports: [
        CommonModule
    ]
})
export class B2cModule {
    static forRoot() : ModuleWithProviders<B2cModule> {
        return {
            ngModule: B2cModule,
            providers: [B2cService, B2cGuard, ApplicationApiService]
        }
    }
}