import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureService } from './feature.service';
import { FeatureGuard } from './feature.guard';
import { ApplicationApiService } from '../services/application-api.service';
import { FeatureDirective } from './feature.directive';

@NgModule({
    declarations: [FeatureDirective],
    exports: [FeatureDirective],
    imports: [
        CommonModule
    ]
})
export class FeatureModule {
    static forRoot() : ModuleWithProviders<FeatureModule> {
        return {
            ngModule: FeatureModule,
            providers: [FeatureService, FeatureGuard, ApplicationApiService]
        }
    }
}
