<div class="row pt-3">
    <div class="col-3">
      <mat-form-field>
        <mat-label>Project</mat-label>
        <mat-select [value]="projectId" (selectionChange)="ProjectChanged($event)">
          <mat-option *ngFor="let project of projects"  value="{{project.id}}">
            {{project.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field>
        <mat-label>Site Location</mat-label>
        <mat-select  (selectionChange)="SiteLocationChanged($event)">
          <mat-option *ngFor="let siteLocation of siteLocations" value="{{siteLocation.id}}">
            {{siteLocation.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <kendo-scheduler
    [kendoSchedulerBinding]="events"

    [resources]="resources"
    [selectedDate]="selectedDate"
    [group]="group"
    scrollTime="08:00"
    style="height: 100%; width: 100%"
    workDayStart="07:00"
    [showWorkHours]=true
    [allDaySlot]=false
    >
    <kendo-scheduler-day-view> </kendo-scheduler-day-view>
    <kendo-scheduler-week-view> </kendo-scheduler-week-view>
   </kendo-scheduler>


  <p>deliverycalendar works!</p>
