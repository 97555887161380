import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApplicationApiService, DeliverySearchViewModel } from 'src/app/core/services/application-api.service';
import { formatDate } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-reschedule-delivery-modal',
  templateUrl: './reschedule-delivery-modal.component.html',
  styleUrls: ['./reschedule-delivery-modal.component.scss']
})
export class RescheduleDeliveryModalComponent {
  public isSaving: boolean = false;
  public formGroup: FormGroup = new FormGroup({
    deliveryDate: new FormControl(this.data.deliveryDate, Validators.required),
    deliveryTime: new FormControl(formatDate(this.data.deliveryDate!, 'hh:mm a', 'en-US'), Validators.required),
    deliveryTimeSlot: new FormControl(this.data.timeSlot + "", Validators.required)
  });
  public timeSlots = [{id: 15, name: "15 min"},{id: 30, name: "30 min"},{id: 45, name: "45 min"},{id: 60, name: "1 hr"},
                      {id: 75, name: "75 min"},{id: 90, name: "90 min"},{id: 105, name: "105 min"},{id: 120, name: "2 hr"}];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DeliverySearchViewModel,
    private dialogRef: MatDialogRef<RescheduleDeliveryModalComponent>,
    private applicationApiService: ApplicationApiService,
    private snackBar: MatSnackBar,
  ){}

  public OnCancelClick() {
     this.dialogRef.close();
  }

  public OnSubmit(formGroup: FormGroup) {
    this.isSaving = true;
    let deliveryDate = new Date(formGroup.value['deliveryDate']);
    let deliveryTime = formGroup.value['deliveryTime'];
    let deliveryTimeSlot = formGroup.value['deliveryTimeSlot'];

    let hour = Number(deliveryTime.substr(0, deliveryTime.indexOf(':')));
    if (deliveryTime.indexOf('PM') > 0 && hour != 12)
      hour = hour + 12;

    this.applicationApiService.apiDeliveryUpdateDeliveryStatus(this.data.id, 2, undefined, deliveryDate, hour, deliveryTimeSlot).subscribe({ //TO DO: remove magic number = 2
      next: (result: number) => {
        this.isSaving = false;
        const day = deliveryDate.getDate();
        const month = deliveryDate.getMonth();
        const year = deliveryDate.getFullYear();
        const minute = Number(formGroup.value.deliveryTime.substr(formGroup.value.deliveryTime.indexOf(':') + 1,2));
        const deliveryDateTime = new Date(year, month, day, hour, minute);
        this.data.deliveryDate = deliveryDateTime;
        this.data.status = "Reschedule Requested";
        this.dialogRef.close({ event: 'Reschedule' });
      },
      error: (error: string) => {
        this.isSaving = false;
        console.log('Error from method apiDeliveryUpdateDeliveryStatus: ' + error);
        this.ShowSnackBar('Error Rescheduling Delivery', 'Ok');
      }
    });
  }

  ShowSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
        duration: 3000
    });

  
  }

  get f() {
    return this.formGroup.controls;
  }
}
