import { Component, Input } from '@angular/core';
import { Task } from './tasks';

@Component({
  selector: 'app-delivery-list',
  templateUrl: './delivery-list.component.html',
  styleUrls: ['./delivery-list.component.scss']
})
export class DeliveryListComponent {
  @Input() tasks: Task[];

  constructor()
  {
    this.tasks = new Array<Task>();
  }
}
