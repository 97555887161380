<div id="spinner">
    <div class="d-flex flex-column align-items-center">
        <div class="p-2">
            <h3>Authorization Pending</h3>
            <div>
                <div>
                    <div>Authorization Pending. Please wait...</div>
                    <div>
                        If this message remains on your screen, please notify the application administrator or create a Service Desk ticket.
                    </div>
                </div>
            </div>
        </div>
        <div class="p-2">
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </div>
    </div>
</div>
