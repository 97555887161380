import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApplicationApiService, SearchInspectionViewModel } from 'src/app/core/services/application-api.service';

@Component({
  selector: 'app-approve-visit-modal',
  templateUrl: './approve-visit-modal.component.html',
  styleUrls: ['./approve-visit-modal.component.scss']
})
export class ApproveVisitModalComponent implements OnInit {

  public id = 0;
  public isSaving: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<ApproveVisitModalComponent>,
    private applicationApiService: ApplicationApiService
  ){
    this.id = this.data.id;
  }

  ngOnInit(): void {
  }

  public OnUpdateInspection()
  {
    this.isSaving = true;
    this.applicationApiService.apiInspectionUpdateInspection(this.id,undefined,undefined,undefined,3,undefined) //TO DO: remove magic number = 3
    .subscribe((result: number) => {
      this.isSaving = false;
    });

    this.ref.close();
  }
  
  public OnCancelClick()
  {
     this.ref.close();
  }
  
}


export class Inspection{
  id? = 0;
  status? = "";
  company? = "";
  tradePartnerName? = "";
  inspectionCompanyId? = 0;
  date?= "";
  time? = "";
  trade? = "";
  type? = "";
  description = "";
  specificSiteLocation? = "";
  requestedBy? = "";
}