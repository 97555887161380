<h3 mat-dialog-title><strong>Fail Inspection?</strong></h3>
<mat-dialog-content>
  <p>Are you sure you want to fail this inspection?</p>
  <div class="col-12 px-3">
    <div><span style="font-weight: 500;">ID:</span> {{data.id}}</div>
    <div><span style="font-weight: 500;">Inspection Company:</span> {{data.inspection?.company}}</div>
    <div><span style="font-weight: 500;">Inspection Date:</span> {{data.inspection?.inspectionDateTime | date:"MM/dd/YY"}}</div>
    <div><span style="font-weight: 500;">Inspection Time:</span> {{data.inspection?.inspectionDateTime | date:"hh:mm a"}}</div>
    <div><span style="font-weight: 500;">Site Location:</span> {{data.inspection?.siteLocation}}</div>
    <div><span style="font-weight: 500;">Requested By:</span> {{data.inspection?.requestedBy}}</div>
  </div>
  <div class="col-12 pt-2">
    <mat-progress-bar *ngIf="isSaving" mode="indeterminate" style="width:100%" color="warn"></mat-progress-bar>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="p-3">
  <button mat-raised-button (click)="OnCancelClick()">Cancel</button>&nbsp;&nbsp;
  <button mat-raised-button color="warn" (click)="OnUpdateInspection()" cdkFocusInitial>Fail</button>
</mat-dialog-actions>
