<!-- footer is full-page on all screens -->
<div class="footer text-center py-3">
  <div>
    <a href="https://turnerhelp.tcco.com/assystnet/application.jsp#services"
      >Create Service Desk Support Ticket</a
    >
    | Information Services Desk | 866-275-5335 | Hours: M-F 8am - 7pm EST |
    <a target="_blank" href="https://www.turnerconstruction.com/privacy"
      >Privacy Policy</a
    >
  </div>
  <div>Copyright &copy; 2023 The Turner Corporation. All Rights Reserved.</div>
</div>
