import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_SELECTION_LIST_VALUE_ACCESSOR } from '@angular/material/list';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, from } from 'rxjs';
import { ApplicationApiService, InspectionSearchOptionsViewModel, SearchInspectionViewModel, DivisionViewModel, StatusViewModel, SearchDeliveryOptionsViewModel, UserSearchViewModel } from 'src/app/core/services/application-api.service';
import { ApproveInspectionModalComponent } from './approve-inspection-modal/approve-inspection-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { FailInspectionModalComponent } from './fail-inspection-modal/fail-inspection-modal.component';
import { UpdateInspectionModalComponent } from './update-inspection-modal/update-inspection-modal.component';
import { CancelInspectionModalComponent } from './cancel-inspection-modal/cancel-inspection-modal.component';
import { AdminApproveInspectionModalComponent } from './admin-approve-inspection-modal/admin-approve-inspection-modal.component';
import { AdminFailInspectionModalComponent } from './admin-fail-inspection-modal/admin-fail-inspection-modal.component';
import { ApproveVisitModalComponent } from './approve-visit-modal/approve-visit-modal.component';
import { DenyVisitModalComponent } from './deny-visit-modal/deny-visit-modal.component';
import { EventMessage, EventType, AuthenticationResult, IdTokenClaims } from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';
import { MsalBroadcastService } from '@azure/msal-angular';
import * as FileSaver from 'file-saver';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string,
  tfp?: string,
  city?: string,
  given_name?: string,
  jobTitle?: string,
  oid?: string
};

@Component({
  selector: 'app-inspection-search',
  templateUrl: './inspection-search.component.html',
  styleUrls: ['./inspection-search.component.scss']
})
export class InspectionSearchComponent implements OnInit{
  public inspections: MatTableDataSource<SearchInspectionViewModel> = new MatTableDataSource<SearchInspectionViewModel>();
  public inspectionsList: Array<SearchInspectionViewModel> = new Array<SearchInspectionViewModel>();
  public displayedColumns: string[] = ['id','status','company','tradePartnerName','date', 'time', 'trade','type','specificSiteLocation','visitOptions','actions'];
  public userRole: string | undefined = ""; //"SuperUser", "Super", "QA", "Owner Rep", "Trade Partner", "Inspector"
  public searchFieldVisibility: string = "";
  public approvalFieldsVisibility: string = "";
  public statuses = new Array<DivisionViewModel>();
  public companies = new Array<DivisionViewModel>();
  public inspectionTypes = new Array<DivisionViewModel>();
  public projectAreas = new Array<DivisionViewModel>();
  public projects = new Array<DivisionViewModel>();
  public inspectionCompanies = new Array();
  public projectId: number | undefined;
  //public userObjectId : string | undefined = "";
  public jobTitle : string | undefined = "";
  private readonly _destroying$ = new Subject<void>();
  public isLoading: boolean = false;
  public isSearching: boolean = false;
  public userModel = {} as UserSearchViewModel;

  public reactiveForm: FormGroup = new FormGroup({
    statusId: new FormControl(''),
    companyId: new FormControl(''),
    inspectionTypeId: new FormControl(''),
    fromTime: new FormControl(''),
    toTime: new FormControl(''),
    siteLocation: new FormControl(""),
    projectId: new FormControl('')
  });

  constructor(
    private applicationApiService: ApplicationApiService,
    private msalBroadcastService: MsalBroadcastService
  ){
    this.msalBroadcastService.msalSubject$
    .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
            || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
            || msg.eventType === EventType.SSO_SILENT_SUCCESS),
        takeUntil(this._destroying$)
    )
    .subscribe((result: EventMessage) => {
        let payload = result.payload as AuthenticationResult;
        let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

        console.log("Payload Access Token: " + payload.idToken);
        console.log("Object Id: " + idtoken.oid);
        this.jobTitle = idtoken.jobTitle;
        //this.userObjectId = idtoken.oid;
        this.userRole = idtoken.jobTitle;
    });
  }

  ngOnInit() {
    this.applicationApiService.apiUserGetUser().subscribe((model: UserSearchViewModel) => {
      this.userModel = model;
      this.getInspectionOptions(this.userModel.objectId?.toString());
      });
  }

  public OnSubmit(form: FormGroup) {
    this.isSearching = true;
    let fromTime: Date | undefined = new Date(form.value['fromTime']);
    let toTime: Date | undefined = new Date(form.value['toTime']);
    let companyId: number | undefined = form.value['companyId'];
    let statusId: number | undefined = form.value['statusId'];
    let inspectionId: number | undefined = form.value['inspectionTypeId'];
    let siteLocation: number | undefined = form.value['siteLocation'];
    let project: number | undefined = form.value['projectId'];

    if(form.value['fromTime'] === "")
      fromTime = undefined;
    if(form.value['toTime'] === "")
      toTime = undefined;
    if(form.value['companyId'] === "")
      companyId = undefined;
    if(form.value['statusId'] === "")
      statusId = undefined;
    if(form.value['inspectionId'] === "")
      inspectionId = undefined;
    if(form.value['siteLocation'] === "")
      siteLocation = undefined;
    if(form.value['projectId'] === "")
      project = undefined;

    console.log("Site Location: " + form.value['siteLocation']);
    console.log("Site Location int: " + siteLocation);

    this.getInspections(project, statusId, companyId, inspectionId, fromTime, toTime, siteLocation);
  }

  public searchInspections()
  {
    console.log("Inspection Search");
  }

  private getInspectionOptions(userObjectId: string | undefined) {
    this.applicationApiService.apiInspectionInspectionSearchOptions(userObjectId)
    .subscribe((scheduleOptions: InspectionSearchOptionsViewModel) => {
        let blankOption = {} as DivisionViewModel;
        blankOption.id = undefined;
        blankOption.name = "";

        this.statuses.push(blankOption);
        scheduleOptions.statuses?.forEach(obj => {
          let status = {} as DivisionViewModel;
          status.id = obj.id;
          status.name = obj.name;
          this.statuses.push(status);
        });

        this.companies.push(blankOption);
        scheduleOptions.companies?.forEach(obj => {
          let company = {} as DivisionViewModel;
          company.id = obj.id;
          company.name = obj.name;
          this.companies.push(company);
        });
        if (this.companies.length < 3)
          this.companies.splice(0,1);

        this.inspectionTypes.push(blankOption);
        scheduleOptions.inspectionTypes?.forEach(obj => {
          let inspectionType = {} as DivisionViewModel;
          inspectionType.id = obj.id;
          inspectionType.name = obj.name;
          this.inspectionTypes.push(inspectionType);
        });

        this.projectAreas.push(blankOption);
        scheduleOptions.siteLocations?.forEach(obj => {
          let siteLocation = {} as DivisionViewModel;
          siteLocation.id = obj.id;
          siteLocation.name = obj.name;
          this.projectAreas.push(siteLocation);
          console.log("Site Location: " + siteLocation.id);
        });

        this.inspectionCompanies.push(blankOption);
        scheduleOptions.inspectionCompanies?.forEach(obj => {
          let inspectionCompanyOptions = {} as DivisionViewModel;
          inspectionCompanyOptions.id = obj.id;
          inspectionCompanyOptions.name = obj.name;
          this.inspectionCompanies.push(inspectionCompanyOptions);
        });

        this.projects.push(blankOption);
        scheduleOptions.projects?.forEach(obj => {
          let project = {} as DivisionViewModel;
          project.id = obj.id;
          project.name = obj.name;
          this.projects.push(project);
        })
        if (this.projects.length < 3)
        {
          this.projects.splice(0,1);
          this.projectId = this.projects[0].id;
        }

      });

  }

  private getInspections(projectId: number| undefined,
                         statusId: number | undefined,
                         companyId: number | undefined,
                         inspectionTypeId: number | undefined,
                         dateFrom: Date | undefined,
                         dateTo: Date | undefined,
                         siteLocation: number | undefined) {
    this.applicationApiService.apiInspectionSearchInspections(projectId, dateFrom, dateTo, statusId, companyId, inspectionTypeId, siteLocation)
      .subscribe((inspections: SearchInspectionViewModel[]) => {
        this.inspectionsList = inspections;
        this.inspections = new MatTableDataSource<SearchInspectionViewModel>(inspections);
        this.isSearching = false;
        });
  }

  public ExportData(json: any) {
    import("xlsx").then(xlsx => {
        console.log('JSON',json);
        const worksheet = xlsx.utils.json_to_sheet(json);
        const workbook = { Sheets: { 'Export': worksheet }, SheetNames: ['Export'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.SaveAsExcelFile(excelBuffer);
    });
  }

  private SaveAsExcelFile(buffer: any): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, 'Inspections Search Export' + EXCEL_EXTENSION);
  }
}