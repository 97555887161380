/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { appConfiguration, APP_CONFIG } from './app/core/configuration/app-configuration';
import { API_BASE_URL } from './app/core/services/application-api.service';

import "./polyfills";



(async () => {
  const appConfig = (await fetch(
    'assets/configuration/app-configuration.json'
  ).then((res) => res.json())) as appConfiguration;

  //if (appConfig.production) {
    enableProdMode();
  //}

  console.log('appConfig:', appConfig);

  platformBrowserDynamic([
    { provide: API_BASE_URL, useValue: appConfig.apiRootUrl },
    { provide: APP_CONFIG, useValue: appConfig }
  ])
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
})();
