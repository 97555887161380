import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicationApiService, DeliverySearchViewModel, SearchDeliveryOptionsViewModel, UserSearchViewModel } from 'src/app/core/services/application-api.service';
import { EventMessage, EventType, AuthenticationResult, IdTokenClaims } from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';
import { MsalBroadcastService } from '@azure/msal-angular';
import * as FileSaver from 'file-saver';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppComponent } from 'src/app/app.component';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string,
  tfp?: string,
  city?: string,
  given_name?: string,
  jobTitle?: string,
  oid?: string
};

@Component({
  selector: 'app-search-delivery',
  templateUrl: './search-delivery.component.html',
  styleUrls: ['./search-delivery.component.scss']
})
export class SearchDeliveryComponent implements OnInit {
  public deliveries: DeliverySearchViewModel[] = [];
  public userRole: string | undefined = ""; //"Contractor", "Scheduler"
  public statuses = new Array();
  public tradePartners = new Array();
  public projects = new Array();
  private readonly _destroying$ = new Subject<void>();
  public isLoading: boolean = false;
  public reactiveForm: FormGroup = new FormGroup({
    project: new FormControl(''),
    status: new FormControl(''),
    tradePartner: new FormControl(''),
    startTime: new FormControl(''),
    endTime: new FormControl('')
  });
  public isSearching: boolean = false;
  public projectSelectedValue: number = 0;
  public tradePartnerSelectedValue: number = 0;
  public userModel = {} as UserSearchViewModel;

  constructor(private applicationApiService: ApplicationApiService,
    private msalBroadcastService: MsalBroadcastService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private appComponent: AppComponent
    )
  {
    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
        || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        || msg.eventType === EventType.SSO_SILENT_SUCCESS),
      takeUntil(this._destroying$))
    .subscribe((result: EventMessage) => {
      let payload = result.payload as AuthenticationResult;
      let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

      console.log("Payload Access Token: " + payload.idToken);
      console.log("Object Id: " + idtoken.oid);
    });
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.applicationApiService.apiUserGetUser().subscribe(
      {
        next:(model: UserSearchViewModel) => {
          this.userModel = model;
          this.userRole = model.role?.toString();
          this.GetSearchDeliveryOptions(this.userModel.objectId?.toString());
        },
        error: (error: string) => {
          this.ShowSnackBar('Authentication error', 'Ok');
          this.appComponent.logout();
          this.isLoading = false;
        }
      });
  }

  public OnSubmit(form: FormGroup) {
    this.isSearching = true;
    let fromTime: Date | undefined = new Date(form.value['startTime']);
    let fromDateString: string = "";
    if (fromTime?.getMonth().toString() != "NaN")
    {
      fromDateString = fromTime.getFullYear() + "-" + (fromTime.getMonth() + 1).toString().padStart(2, '0') + "-" + fromTime.getDate().toString().padStart(2, '0') + "T00:00:00Z";
    }
    else
    {
      fromTime = undefined;
    }
    let toTime: Date | undefined = new Date(form.value['endTime']);
    let toDateString: string = "";
    if (toTime.getMonth().toString() != "NaN")
    {
      toDateString = toTime.getFullYear() + "-" + (toTime.getMonth() + 1).toString().padStart(2, '0') + "-" + toTime.getDate().toString().padStart(2, '0') + "T00:00:00Z";
    }
    else
    {
      toTime = undefined;
    }

    console.log("project Value: " + form.value['project']);

    this.SearchDeliveries( (form.value['project'] == 0)?undefined:form.value['project'],
                          fromTime,
                          toTime,
                          form.value['status'],
                          (form.value['tradePartner'] == 0)?undefined:form.value['tradePartner']);
  }

  private GetSearchDeliveryOptions(userId: string| undefined)  {
    this.applicationApiService.apiDeliverySearchDeliveryOptions(userId)
    .subscribe((deliveryOptions: SearchDeliveryOptionsViewModel) => {
      this.statuses.push({id: undefined, name: ""});
      deliveryOptions.deliveryStatuses?.forEach(obj => {
        this.statuses.push({id: obj.id, name: obj.name});
      });

      if(this.userModel.role?.toString() == "SuperUser")
      {
        this.tradePartners.push({id: undefined, name: ""});
      }
      deliveryOptions.tradePartners?.forEach(obj => {
        this.tradePartners.push({id: obj.id, name: obj.name});
      });

      if(this.userModel.role?.toString() == "SuperUser")
      {
        this.projects.push({id: undefined, name: ""});
      }

      deliveryOptions.projects?.forEach(obj => {
        this.projects.push({id: obj.id, name: obj.name});
      } );


      if(this.userModel.role?.toString() == "Inspector" || this.userModel.role?.toString() == "Trade Partner")
      {
        this.projectSelectedValue = this.projects[0].id;
        this.tradePartnerSelectedValue = this.tradePartners[0].id;
        console.log("tradePartnerSelectedValue: " + this.tradePartnerSelectedValue);
      }

      this.reactiveForm = this.fb.group({
        project: new FormControl(this.projectSelectedValue,[]),
        status: new FormControl(''),
        tradePartner: new FormControl(this.tradePartnerSelectedValue,[]),
        startTime: new FormControl(''),
        endTime: new FormControl('')
      });

      this.isLoading = false;
    });
  }

  private SearchDeliveries(projectID: number | undefined,
                           dateFrom: Date | undefined,
                           dateTo: Date | undefined,
                           statusId: number | undefined,
                           companyId: number | undefined) {

    this.applicationApiService.apiDeliverySearchDelivery(projectID,dateFrom,dateTo,statusId,companyId)
      .subscribe((result: DeliverySearchViewModel[]) => {
        this.deliveries = result;
        this.isSearching = false;
      });
  }

  public ExportData(json: any) {
    import("xlsx").then(xlsx => {
        console.log('JSON',json);
        const worksheet = xlsx.utils.json_to_sheet(json);
        const workbook = { Sheets: { 'Export': worksheet }, SheetNames: ['Export'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.SaveAsExcelFile(excelBuffer);
    });
  }

  private SaveAsExcelFile(buffer: any): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, 'Deliveries Search Export' + EXCEL_EXTENSION);
  }

  ShowSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
        duration: 3000
    });
  }
}
