<table mat-table matSort matSortDisableClear [dataSource]="deliveriesTable" class="mat-elevation-z4" matSortActive="id" matSortDirection="desc">
    <!-- id -->
    <ng-container matColumnDef="id">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Id</th>
        <td mat-cell *matCellDef="let r">{{r.id}}</td>
    </ng-container>

    <!-- status -->
    <ng-container matColumnDef="status">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Status</th>
        <td mat-cell class="bold" *matCellDef="let r">{{r.status}}</td>
    </ng-container>

    <!-- company -->
    <ng-container matColumnDef="company">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Company</th>
        <td mat-cell *matCellDef="let r">{{r.company}}</td>
    </ng-container>

    <!-- date -->
    <ng-container matColumnDef="deliveryDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let r">{{r.deliveryDate | date:"MM/dd/YY"}}</td>
    </ng-container>

    <!-- time -->
    <ng-container matColumnDef="time">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Time</th>
        <td mat-cell *matCellDef="let r">{{r.deliveryDate | date:"hh:mm a"}}</td>
    </ng-container>

    <!-- truck -->
    <ng-container matColumnDef="truck">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Truck</th>
        <td mat-cell *matCellDef="let r">{{r.truck}}</td>
    </ng-container>

    <!-- description -->
    <ng-container matColumnDef="description">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let r">{{r.description}}</td>
    </ng-container>

    <!-- site location -->
    <ng-container matColumnDef="siteLocation">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Site Location</th>
        <td mat-cell *matCellDef="let r">{{r.siteLocation}}</td>
    </ng-container>

    <!-- requestedBy -->
    <ng-container matColumnDef="requestedBy">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Requested By</th>
        <td mat-cell *matCellDef="let r">{{r.requestedBy}}</td>
    </ng-container>

    <!-- visitOptions -->
    <ng-container  matColumnDef="visitOptions">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Approve Delivery</th>
        <td mat-cell *matCellDef="let r">
            <mat-icon *ngIf="CanApproveVisit(r.status)" (click)="ApproveInspectionPopup(r)" matTooltip="Approve Delivery" style="color:green; margin-right: 10px">thumb_up_alt</mat-icon>
            <mat-icon *ngIf="CanRescheduleVisit(r.status)" (click)="RescheduleDeliveryPopup(r)" matTooltip="Reschedule Delivery">event_repeat</mat-icon>
        </td>
    </ng-container>

    <!-- actions -->
    <ng-container matColumnDef="actions">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let r">
            <mat-icon *ngIf="CanDeliver(r.status)" (click)="ConfirmDeliveryPopup(r)" matTooltip="Confirm Delivery" style="color:green; margin-right: 10px">check_circle</mat-icon>
            <mat-icon *ngIf="CanEditDelivery(r.status)" (click)="UpdateDeliveryPopup(r)" matTooltip="Edit Delivery" class="blue" style="margin-right: 10px">edit</mat-icon>
            <mat-icon *ngIf="CanCancelDelivery(r.status)" (click)="CancelDeliveryPopup(r)" matTooltip="Cancel Delivery" style="color:red; margin-right: 10px">cancel</mat-icon>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let r; columns: displayedColumns"></tr>
</table>
<mat-paginator class="mat-elevation-z4"
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 25, 50, 100]"
    showFirstLastButtons="">
</mat-paginator>
