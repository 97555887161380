<mat-dialog-content>
<div class="d-flex flex-column align-items-stretch">
    <div class="p-2" style="text-align:center">
        <h2>
            {{data.title}}
        </h2>
        <div class="p-2 dialog-content" style="white-space: pre-wrap">
            {{data.content}}
        </div>
    </div>
</div>
</mat-dialog-content>

<mat-dialog-actions align="end" class="p-2">
    <button mat-button mat-dialog-close color="basic"> Cancel </button>
    <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial> Yes, go ahead </button>&nbsp;&nbsp;
</mat-dialog-actions>
