import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { protectedResources } from './auth-config';
import { InspectionScheduleOptionsViewModel, PostInspectionRequestBody, SearchInspectionViewModel } from './core/services/application-api.service';
import { InspectionSearchOptionsViewModel } from './core/services/application-api.service';

@Injectable({
    providedIn: 'root'
})
export class InspectionService {
    url = protectedResources.scheduleInspectionOptions.endpoint;
    searchOptionsUrl = protectedResources.inspectionSearchOptions.endpoint;
    searchUrl = protectedResources.inspectionSearch.endpoint;
    postInspectionUrl = protectedResources.postInspection.endpoint

    constructor(private http: HttpClient) { }

    getScheduleInspectionOptions(id: number) {
        return this.http.get<InspectionScheduleOptionsViewModel>(this.url + "?userid=" + id );
    }

    getInspectionSearchOptions(id: number) {
        return this.http.get<InspectionSearchOptionsViewModel>(this.searchOptionsUrl + "?userId=" + id );
    }

    getInspectionSearch(statusId: string,
                        companyId: string,
                        inspectionTypeId: string,
                        dateFrom: string,
                        dateTo: string) {
        let querystringsuffix = "";
        if (statusId != "")
            querystringsuffix += "&statusId=" + statusId;
        if (companyId != "")
            querystringsuffix += "&companyId=" + companyId;
        if (inspectionTypeId != "")
            querystringsuffix += "&inspectionTypeId=" + inspectionTypeId;
        if (dateFrom != "")
            querystringsuffix += "&dateFrom=" + dateFrom;
        if (dateTo != "")
            querystringsuffix += "&dateTo=" + dateTo;
        return this.http.get<SearchInspectionViewModel[]>(this.searchUrl + "?" + querystringsuffix);
    }

    addInspection(body: pirBody ) {
        return this.http.post<pirBody>(this.postInspectionUrl, body);
    }
}

export class pirBody {
    ProjectId = 0;
    SiteLocationId = 0;
    InspectionCompanyId = 0;
    InspectionTypeId = 0;
    StatusId = 0;
    ContactId = 0;
    SubmittalNumber = "";
    Description = "";
    InspectionDate = new Date('1/1/1900');
}