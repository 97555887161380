<div [hidden]="!isLoading">
  <div class="d-flex flex-column align-items-center">
    <div class="p-2">
        <h2>Loading Form...</h2>
        <div>Please wait...</div>
    </div>
    <div class="p-2">
        <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
<div [hidden]="isLoading">
  <mat-card class="p-3">
    <h2 style="color:#00275d;">Schedule an Inspection&nbsp;<mat-icon style="vertical-align: sub; margin-left: 5px; margin-right: 5px;">search</mat-icon></h2>
    <form [formGroup]="reactiveForm" (ngSubmit)="OnSubmit(reactiveForm)">
      <div class="row">
        <div class="col-12 col-lg-3 col-md-4">
          <mat-form-field style="width:100%">
            <mat-label>Inspection Date</mat-label>
            <input matInput [matDatepicker]="inspectionDate" formControlName="inspectionDate">
            <mat-datepicker-toggle matSuffix [for]="inspectionDate"></mat-datepicker-toggle>
            <mat-datepicker #inspectionDate></mat-datepicker>
            <mat-error *ngIf="f['inspectionDate'].errors?.['required']">
              Inspection Date is Required
            </mat-error>
          </mat-form-field>
        </div>
        <!--
        <div class="col-12 col-lg-3 col-md-5">
          <ngx-mat-timepicker-field syle="width:100%" color="primary" [format]="12" formControlName="inspectionTime"></ngx-mat-timepicker-field>
          <mat-error *ngIf="f['inspectionTime'].errors?.['required']">
            Inspection Time is Required
          </mat-error>
        </div>
        -->
        <div class="col">
          <div class="row">
            <div class="col">
            <b>Time Start {{convertDate(startSlider.value)}} - {{convertDate(endSlider.value)}} End</b>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-slider style="width:250px" [min]="TimeMin" [max]="TimeMax" [step]="900000">
                <input [(value)]="StartTime"  matSliderStartThumb #startSlider>
                <input [(value)]="EndTime"  matSliderEndThumb #endSlider>
              </mat-slider>
            </div>
          </div>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-12 col-lg-3 col-md-5">
          <mat-form-field style="width:100%">
            <mat-label>Project</mat-label>
            <mat-select (selectionChange)="OnProjectChange($event.value)" formControlName="project" value="projectId">
              <mat-option *ngFor="let project of projects" value="{{project.id}}">
                {{project.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="f['project'].errors?.['required']">
              Project is Required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-12 col-lg-3 col-md-5">
          <mat-form-field style="width:100%">
            <mat-label>Trade Partner</mat-label>
            <mat-select formControlName="company">
              <mat-option *ngFor="let company of companies" value="{{company.id}}">
                {{company.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="f['company'].errors?.['required']">
              Trade Partner is Required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-6 col-md-6">
          <mat-form-field style="width:100%">
              <mat-label>Division</mat-label>
              <mat-select (selectionChange)="OnDivisionChange($event.value)" formControlName="division">
                <mat-option *ngFor="let division of divisions" value={{division.id}}>
                  {{division.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="f['division'].errors?.['required']">
                  Division is Required
              </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-3 col-md-5">
          <mat-form-field style="width:100%">
            <mat-label>Specific Site Location</mat-label>
            <mat-select formControlName="siteLocation">
              <mat-option *ngFor="let siteLocation of siteLocations" value={{siteLocation.id}}>
                {{siteLocation.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="f['siteLocation'].errors?.['required']">
              Specific Site Location
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-12 col-lg-3 col-md-5">
          <mat-form-field style="width:100%">
            <mat-label>Inspection Type</mat-label>
            <mat-select formControlName="inspectionType">
            <mat-option *ngFor="let inspectionType of inspectionTypes" value={{inspectionType.id}}>
              {{inspectionType.name}}
            </mat-option>
            </mat-select>
            <mat-error *ngIf="f['inspectionType'].errors?.['required']">
                Inspection Type is Required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-3 col-md-5">
          <mat-form-field style="width:100%">
            <mat-label>Submittal/RFI Number</mat-label>
            <input type="text" matInput formControlName="RFInumber" max="100">
          </mat-form-field>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-12 col-lg-6">
          <mat-form-field style="width:100%">
            <mat-label>List all items inspected</mat-label>
            <textarea matInput formControlName="description"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-12">
          <button [disabled]="submitdisabled" mat-raised-button type="submit" color="primary" [disabled]="this.reactiveForm.invalid">Submit Inspection Request</button>
        </div>
      </div>
      <!-- <div class="row pt-2" [hidden]="isMapHidden">
        <img src="{{appConfig.azureBlobStorageURL}}{{reactiveForm.value.project}}">
      </div> -->
    </form>
  </mat-card>
</div>
