<div class="demo-container">
    <div
    kendoDropTargetContainer
    dropTargetFilter=".drop-zone, td:has(.drop-zone)"
    >

    <div class="row pt-3">
        <div class="col-3">
          <mat-form-field>
            <mat-label>Project</mat-label>
            <mat-select [value]="projectId" (selectionChange)="ProjectChanged($event)">
              <mat-option *ngFor="let project of projects"  value="{{project.id}}">
                {{project.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field>
            <mat-label>Site Location</mat-label>
            <mat-select  (selectionChange)="SiteLocationChanged($event)">
              <mat-option *ngFor="let siteLocation of siteLocations" value="{{siteLocation.id}}">
                {{siteLocation.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

    <kendo-scheduler
        #scheduler
        [kendoSchedulerBinding]="events"
        [kendoSchedulerReactiveEditing]="createFormGroup"
        [selectedDate]="selectedDate"
        [group]="group"
        [resources]="resources"
        [style.height.px]="712"
        [style.width.px]="calendarWidth"
        startTime="07:00"
        endTime="17:00"
        [allDaySlot]=false
        (save)="onSave()"
        (dragEnd)="onDragEnd($event)"
        (resizeEnd)="onResizeEnd($event)"
        (eventDblClick)="onEventDblClick($event)"
        (selectedViewIndexChange)="onChangeView($event)"
        (remove)="onDelete($event)"
    >
        <kendo-scheduler-day-view> </kendo-scheduler-day-view>
        <kendo-scheduler-week-view> </kendo-scheduler-week-view>
    </kendo-scheduler>
    </div>
</div>
