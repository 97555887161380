import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApplicationApiService, DeliverySearchViewModel } from 'src/app/core/services/application-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-confirm-delivery-modal',
  templateUrl: './confirm-delivery-modal.component.html',
  styleUrls: ['./confirm-delivery-modal.component.scss']
})
export class ConfirmDeliveryModalComponent {
  public isSaving: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DeliverySearchViewModel,
    private dialogRef: MatDialogRef<ConfirmDeliveryModalComponent>,
    private applicationApiService: ApplicationApiService,
    private snackBar: MatSnackBar,
  ){}

  public OnConfirmDeliveryClick() {
    this.isSaving = true;
    this.applicationApiService.apiDeliveryUpdateDeliveryStatus(this.data.id, 5, undefined, undefined, 0, 0).subscribe({ //TO DO: remove magic number = 5
      next: (result: number) => {
        this.data.status = 'Delivered';
        this.isSaving = false;
        this.dialogRef.close({ event: 'Confirm' });
      },
      error: (error: string) => {
        this.isSaving = false;
        console.log('Error from method apiDeliveryUpdateDeliveryStatus: ' + error);
        this.ShowSnackBar('Error Approving Delivery', 'Ok');
      }
    });
  }

  public OnCancelClick() {
     this.dialogRef.close();
  }

  ShowSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
        duration: 3000
    });
  }
}

