import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { ApplicationApiService, UserSearchViewModel, DeliverySearchViewModel, SearchInspectionViewModel } from 'src/app/core/services/application-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public deliveries: DeliverySearchViewModel[] = [];
  public inspections: Array<SearchInspectionViewModel> = new Array<SearchInspectionViewModel>();
  public userObjectId : string | undefined = "";
  public jobTitle : string | undefined = "";
  public canSeeDeliveries(){return (this.jobTitle != "Inspector");}
  public userModel = {} as UserSearchViewModel;
  public isLoading = false;

  constructor(
    private applicationApiService: ApplicationApiService,
    private appComponent: AppComponent,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.applicationApiService.apiUserGetUser().subscribe(
      {
        next:(model: UserSearchViewModel) => {
          this.userModel = model;
          this.GetDeliveries(this.userModel.objectId?.toString());
          this.GetInspections(this.userModel.objectId?.toString());

          this.isLoading = false;
        },
        error: (error: string) => {
          this.ShowSnackBar('Authentication error', 'Ok');
          this.appComponent.logout();
          this.isLoading = false;
        }
      });
  }

  private GetDeliveries(userId: string | undefined)
  {
    this.applicationApiService.apiDeliveryGetActionItems(userId)
    .subscribe((result: DeliverySearchViewModel[]) => {
      this.deliveries = result;
    });
  }

  private GetInspections(userId: string | undefined)
  {
    this.applicationApiService.apiInspectionGetActionItems(userId)
    .subscribe((result: SearchInspectionViewModel[]) => {
      this.inspections = result;
    });
  }

  ShowSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
        duration: 3000
    });
  }
}

