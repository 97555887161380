<kendo-listview [data]="tasks">
    <ng-template kendoListViewHeaderTemplate>
        <div class="header">For Delivery</div>
    </ng-template>
    <ng-template kendoListViewItemTemplate let-dataItem="dataItem" let-isFirst="isFirst">
        <kendo-card width="174px">
        <kendo-card-header class="k-hstack">
            <div>
            <h1 kendoCardTitle>#{{ dataItem.orderID }}</h1>
            <p kendoCardSubtitle>{{ dataItem.shipping }}</p>
            </div>
        </kendo-card-header>

        <kendo-card-body>
            <p>
            {{ dataItem.address }}
            </p>
        </kendo-card-body>
        </kendo-card>
    </ng-template>
</kendo-listview>