import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { UnauthorizedComponent } from './routes/unauthorized/unauthorized.component';
import { AuthComponent } from './core/components/auth/auth.component';
import { HomeComponent } from './routes/home/home.component';
import { SearchDeliveryComponent } from './routes/delivery/search-delivery/search-delivery.component';
import { AddDeliveryComponent } from './routes/delivery/add-delivery/add-delivery.component';
import { InspectionScheduleComponent } from './routes/inspection/schedule/schedule.component';
import { InspectionSearchComponent } from './routes/inspection/inspection-search/inspection-search.component';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { TodoViewComponent } from './routes/todo-view/todo-view.component';
import { BrowserUtils } from '@azure/msal-browser';
import { B2cGuard } from './core/auth/b2c/b2-c.guard';
import { SuperUserGuard } from './core/auth/SuperUser/SuperUser.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: 'home', canActivate: [B2cGuard,MsalGuard], component: HomeComponent },
  { path: 'auth', component: MsalRedirectComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'delivery/search', canActivate: [B2cGuard,MsalGuard], component: SearchDeliveryComponent},
  { path: 'delivery/add', canActivate: [B2cGuard,MsalGuard], component: AddDeliveryComponent},
  { path: 'inspection/schedule', canActivate: [B2cGuard,MsalGuard], component: InspectionScheduleComponent},
  { path: 'inspection/search', canActivate: [B2cGuard,MsalGuard], component: InspectionSearchComponent },
  { path: 'todo-view', canActivate: [B2cGuard,MsalGuard], component: TodoViewComponent},
  { path: 'project', canActivate: [B2cGuard,MsalGuard,SuperUserGuard], loadChildren: () => import('./routes/project-admin/project-admin.module').then(m => m.ProjectAdminModule) },
  { path: 'users', canActivate: [B2cGuard,MsalGuard,SuperUserGuard], loadChildren: () => import('./routes/user-admin/user-admin.module').then(m => m.UserAdminModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes or popups
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
