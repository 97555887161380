import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { TodoService, Todo } from './../../todo.service';
import { InspectionService } from 'src/app/inspection.service';
import { InspectionScheduleOptionsViewModel, SearchInspectionViewModel } from 'src/app/core/services/application-api.service';

@Component({
    selector: 'app-todo-view',
    templateUrl: './todo-view.component.html',
    styleUrls: ['./todo-view.component.css']
})
export class TodoViewComponent implements OnInit {

    todo?: Todo;

    todos: Todo[] = [];

    scheduleOptions: InspectionScheduleOptionsViewModel = {} as InspectionScheduleOptionsViewModel;

    displayedColumns = ['status', 'description', 'edit', 'remove'];

    constructor(private service: TodoService,
                private inspectionService: InspectionService) { }

    ngOnInit(): void {
        this.getTodos();
        this.getScheduleOptions();
        this.getInspections();
    }

    getScheduleOptions(): void {
        this.inspectionService.getScheduleInspectionOptions(1)
            .subscribe((scheduleOptions: InspectionScheduleOptionsViewModel) => {
                this.scheduleOptions = scheduleOptions;
            });
    }

    

  private getInspections()
  {
    /*
    this.inspectionService.getInspectionSearch()
    .subscribe((inspections: SearchInspectionViewModel[]) => {
        
      });
      */
  }

    getTodos(): void {
        this.service.getTodos()
            .subscribe((todos: Todo[]) => {
                this.todos = todos;
            });
    }

    addTodo(add: NgForm): void {
        this.service.postTodo(add.value).subscribe(() => {
            this.getTodos();
        })
        add.resetForm();
    }

    checkTodo(todo: Todo): void {
        this.service.editTodo(todo).subscribe();
    }

    removeTodo(id: string): void {
        this.service.deleteTodo(+id).subscribe(() => {
            this.getTodos();
        })
    }
}
