import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApplicationApiService, InspectionCompanyViewModel } from 'src/app/core/services/application-api.service';

@Component({
  selector: 'app-update-inspection-modal',
  templateUrl: './update-inspection-modal.component.html',
  styleUrls: ['./update-inspection-modal.component.scss']
})
export class UpdateInspectionModalComponent implements OnInit {
  public isSaving: boolean = false;
  public id = 0;
  public inspectionCompanyId = -1;
  public date: string = "";
  public time: string = "";
  public timeSlot: number = 15;
  public projectId: number = 1;

  public timeSlots = [{id: 15, name: "15 min"},{id: 30, name: "30 min"},{id: 45, name: "45 min"},{id: 60, name: "1 hr"},
                      {id: 75, name: "75 min"},{id: 90, name: "90 min"},{id: 105, name: "105 min"},{id: 120, name: "2 hr"}];
                      
  public TimeMin: number = new Date('1/1/1900 7:00').getTime();
  public TimeMax: number   = new Date('1/1/1900 17:00').getTime();
  public StartTime: number = new Date('1/1/1900 8:00').getTime();
  public EndTime: number = new Date('1/1/1900 10:00').getTime();

  public inspectionDateStart: Date = new Date();

  @Input() inspectionCompanies = new Array();

  public formGroup: FormGroup = new FormGroup({
    inspectionCompanyId: new FormControl(this.data.inspectionCompanyId, Validators.required),
    inspectionDate: new FormControl(new Date(this.data.dateStart), Validators.required)
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<UpdateInspectionModalComponent>,
    private applicationApiService: ApplicationApiService
  ){}

  ngOnInit(): void {
    
    this.id = this.data.id;
    
    this.inspectionCompanyId = this.data.inspectionCompanyId;
    this.inspectionCompanies = this.data.inspectionCompanies;
    this.applicationApiService.apiInspectionCompanyGetInspectionCompaniesByProject(1)
        .subscribe((inspectionCompanyViewModel: InspectionCompanyViewModel[]) =>
        {
          this.inspectionCompanies = inspectionCompanyViewModel;
          console.log("inspectionCompanies: " + this.inspectionCompanies.length);
        });

    
  }

  get f() {
    return this.formGroup.controls;
  }

  public OnSubmit(formGroup: FormGroup) {
    let someDateStart = this.inspectionDateStart;

    someDateStart.setUTCHours( (new Date(this.StartTime)).getUTCHours()-1);
    someDateStart.setUTCMinutes( (new Date(this.StartTime)).getUTCMinutes());
    someDateStart.setSeconds(0);

    let someDateEnd = new Date(this.inspectionDateStart);
    someDateEnd.setUTCHours( (new Date(this.EndTime)).getUTCHours()-1);
    someDateEnd.setMinutes( (new Date(this.EndTime)).getMinutes());
    someDateEnd.setSeconds(0);
    
    this.applicationApiService.apiInspectionUpdateInspection(this.id,
                                                             formGroup.value['inspectionCompanyId'],
                                                             this.getLocalTime(someDateStart),
                                                             this.getLocalTime(someDateEnd),
                                                             3,
                                                             undefined) //TO DO: remove magic number = 3
    .subscribe((result: number) => {});
    

    this.ref.close();
  }

  public OnCancelClick() {
     this.ref.close();
  }

  private combineDateTime(date: any, time: string) {
    // build delivery date with time
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    let hour = Number(time.substr(0, time.indexOf(':')));
    const minute = Number(time.substr(time.indexOf(':') + 1,2));
    if (time.indexOf('PM') > 0 && hour != 12)
      hour = hour + 12;
    const deliveryDateTime = new Date(year, month, day, hour, minute);
    return deliveryDateTime;
  }

  

  public convertDate(t: string)
  {
    let hour = new Date(+t).toTimeString().split(" ")[0].split(":")[0];
    let meridian = "AM"


    if (+hour >= 13)
    {
      hour = (+hour - 12).toString();
      meridian = "PM";
    }
    else if (+hour == 12)
    {
      hour = (+hour).toString();
      meridian = "PM";
    }
    else
    {
      hour = (+hour).toString();
    }

    return hour
    + ":"
    + new Date(+t).toTimeString().split(" ")[0].split(":")[1]
    + " " + meridian;
  }

  public getLocalTime(d: Date)
  {
    let someDateString = (d.getMonth()+1) + '/' +
      d.getDate() + '/' +
      d.getFullYear() + ' ' +
      d.getHours() + ":" +
      d.getMinutes() + ":" +
      d.getSeconds() + ' UTC';

    return new Date(Date.parse(someDateString));
  }
}
