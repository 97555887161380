<form [formGroup]="formGroup" (ngSubmit)="OnUpdateInspection(formGroup)">
    <h3 mat-dialog-title><strong>Reschedule Inspection?</strong></h3>
    <mat-dialog-content>
      <p><span style="font-weight: 500;">Inspection ID:</span> {{data.id}}</p>
      <div class="col-12">
         <mat-form-field>
            <mat-label>Inspection Date</mat-label>
            <input matInput formControlName="inspectionDate" [matDatepicker]="inspectionDate">
            <mat-datepicker-toggle matIconSuffix [for]="inspectionDate"></mat-datepicker-toggle>
            <mat-datepicker #inspectionDate></mat-datepicker>
            <mat-error *ngIf="f['inspectionDate'].errors?.['required']">
                inspection Date is Required
            </mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 pt-2">
        <ngx-mat-timepicker-field syle="width:100%" color="warn" [format]="12" formControlName="inspectionTime"></ngx-mat-timepicker-field>
        <mat-error *ngIf="f['inspectionTime'].errors?.['required']">
          inspection Time is Required
        </mat-error>
      </div>
      <div class="col-12 pt-2">
        <mat-form-field>
          <mat-label>Time Slot</mat-label>
          <mat-select formControlName="inspectionTimeSlot" [(value)]="timeSlot">
            <mat-option *ngFor="let timeSlot of timeSlots" value="{{timeSlot.id}}">{{timeSlot.name}}</mat-option>
          </mat-select>
          <mat-error *ngIf="f['inspectionTimeSlot'].errors?.['required']">
            Time Slot is Required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 pt-2">
        <mat-progress-bar *ngIf="isSaving" mode="indeterminate" style="width:100%" color="warn"></mat-progress-bar>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="p-3">
      <button mat-raised-button type="button" (click)="OnCancelClick()">Cancel</button>&nbsp;&nbsp;
      <button mat-raised-button color="warn" type="submit">Reschedule</button>
    </mat-dialog-actions>
  </form>