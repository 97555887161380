<form [formGroup]="formGroup" (ngSubmit)="OnSubmit(formGroup)">
  <h3 mat-dialog-title><strong>Update Inspection?</strong></h3>
  <mat-dialog-content>
    <p><span style="font-weight: 500;">Inspection ID:</span> {{data.id}}</p>
    <div class="col-12">
      <mat-form-field>
        <mat-label>Inspection Company</mat-label>
        <mat-select formControlName="inspectionCompanyId">
          <mat-option *ngFor="let inspectionCompany of inspectionCompanies" value="{{inspectionCompany.id}}">
            {{inspectionCompany.name}}
          </mat-option>
          <mat-error *ngIf="f['inspectionCompanyId'].errors?.['required']">
            inspection Date is Required
          </mat-error>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12">
      <mat-form-field>
        <mat-label>inspection Date</mat-label>
        <input matInput formControlName="inspectionDate" [(ngModel)]="inspectionDateStart" [matDatepicker]="inspectionDate">
        <mat-datepicker-toggle matIconSuffix [for]="inspectionDate"></mat-datepicker-toggle>
        <mat-datepicker #inspectionDate></mat-datepicker>
          <mat-error *ngIf="f['inspectionDate'].errors?.['required']">
            inspection Date is Required
          </mat-error>
      </mat-form-field>
    </div>
    <div class="col-12 pt-2">
      <div class="col">
        <div class="row">
          <div class="col">
          <b>Time Start {{convertDate(startSlider.value)}} - {{convertDate(endSlider.value)}} End</b>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-slider style="width:250px" [min]="TimeMin" [max]="TimeMax" [step]="900000">
              <input [(value)]="StartTime" matSliderStartThumb  #startSlider>
              <input [(value)]="EndTime" matSliderEndThumb #endSlider>
            </mat-slider>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 pt-2">
      <mat-progress-bar *ngIf="isSaving" mode="indeterminate" style="width:100%" color="warn"></mat-progress-bar>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="p-3">
    <button mat-raised-button type="button" (click)="OnCancelClick()">Cancel</button>&nbsp;&nbsp;
    <button mat-raised-button color="warn" type="submit">Update</button>
  </mat-dialog-actions>
</form>