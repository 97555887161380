import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApplicationApiService } from 'src/app/core/services/application-api.service';

@Component({
  selector: 'app-cancel-inspection-modal',
  templateUrl: './cancel-inspection-modal.component.html',
  styleUrls: ['./cancel-inspection-modal.component.scss']
})
export class CancelInspectionModalComponent {

  public id = 0;
  public isSaving: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<CancelInspectionModalComponent>,
    private applicationApiService: ApplicationApiService
  )
  {
    this.id = this.data.id;
  }

  ngOnInit(): void {
  }

  public OnUpdateInspection()
  {
    this.isSaving = true;
    this.applicationApiService.apiInspectionUpdateInspection(this.id,undefined,undefined,undefined,4,undefined) //TO DO: remove magic number = 3
    .subscribe((result: number) => {
      this.isSaving = false;
    });

    this.ref.close();
  }
  
  public OnCancelClick()
  {
     this.ref.close();
  }
  
}
