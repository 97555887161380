import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicationApiService, InspectionScheduleOptionsViewModel, PostInspectionRequestBody, SearchDeliveryOptionsViewModel, TradePartnerViewModel, DivisionViewModel, SpecificSiteLocationViewModel, ProjectSearchViewModel, InspectionViewModel, UserSearchViewModel} from 'src/app/core/services/application-api.service';
import { EventMessage, EventType, AuthenticationResult, IdTokenClaims } from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';
import { MsalBroadcastService } from '@azure/msal-angular';
import { APP_CONFIG, appConfiguration } from 'src/app/core/configuration/app-configuration';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string,
  tfp?: string,
  city?: string,
  given_name?: string,
  jobTitle?: string,
  oid?: string
};

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class InspectionScheduleComponent {
  public companies: TradePartnerViewModel[] | undefined;
  public siteLocations: SpecificSiteLocationViewModel[] | undefined;
  public divisions: DivisionViewModel[] | undefined;
  public inspectionTypes: InspectionViewModel[] | undefined;
  public scheduleOptions: InspectionScheduleOptionsViewModel = {};
  public projects: ProjectSearchViewModel[] | undefined;
  //public userObjectId : string | undefined = "";
  private readonly _destroying$ = new Subject<void>();
  public isLoading: boolean = false;
  public userModel = {} as UserSearchViewModel;
  public isMapHidden: boolean = true;

  public TimeMin: number = new Date('1/1/1900 7:00').getTime();
  public TimeMax: number   = new Date('1/1/1900 17:00').getTime();
  public StartTime: number = new Date('1/1/1900 8:00').getTime();
  public EndTime: number = new Date('1/1/1900 10:00').getTime();
  public inspectionDateOnly: Date = new Date();

  public reactiveForm: FormGroup = new FormGroup({
    inspectionDate: new FormControl('', Validators.required),
    project: new FormControl('', Validators.required),
    company: new FormControl({ value: '', disabled: true }, Validators.required),
    division: new FormControl({ value: '', disabled: true }, Validators.required),
    siteLocation: new FormControl({ value: '', disabled: true }, Validators.required),
    inspectionType: new FormControl({ value: '', disabled: true }, Validators.required),
    RFInumber: new FormControl({ value: '', disabled: true }),
    description: new FormControl({ value: '', disabled: true }, Validators.required)
  });
  public submitdisabled: boolean = false;

  constructor(
    private applicationApiService: ApplicationApiService,
    private msalBroadcastService: MsalBroadcastService,
    @Inject(APP_CONFIG) public appConfig: appConfiguration,
  )
  {
    this.msalBroadcastService.msalSubject$
      .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
              || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
              || msg.eventType === EventType.SSO_SILENT_SUCCESS),
          takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        let payload = result.payload as AuthenticationResult;
        let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

        console.log("Payload Access Token: " + payload.idToken);
        console.log("Object Id: " + idtoken.oid);
        //this.userObjectId = idtoken.oid;
      });
  }

  ngOnInit() {
    this.isLoading = true;
    this.applicationApiService.apiUserGetUser().subscribe((model: UserSearchViewModel) => {
        this.userModel = model;
        this.getScheduleOptions(this.userModel.objectId?.toString());
      });
  }

  get f()
  {
    return this.reactiveForm.controls;
  }

  private getScheduleOptions(userObjectId: string | undefined): void {
    this.applicationApiService.apiInspectionScheduleInspectionOptions(userObjectId) //("910c3b4c-e81f-42eb-90ef-b681811a9f56")
      .subscribe((scheduleOptions: InspectionScheduleOptionsViewModel) => {
        this.scheduleOptions = scheduleOptions;
        this.projects = this.scheduleOptions.projects!;
        this.isLoading = false;
      });
  }

  public OnProjectChange(id: string) {
    // enable controls
    this.reactiveForm.controls['company'].enable();
    this.reactiveForm.controls['division'].enable();
    this.reactiveForm.controls['siteLocation'].enable();

    // populate trades, divisions, locations based on project selected
    this.companies = this.scheduleOptions.companies?.filter(i => i.projectId == +id);
    this.divisions = this.scheduleOptions.divisions?.filter(i => i.projectId?.includes(+id));
    this.siteLocations = this.scheduleOptions.specificSiteLocations?.filter(i => i.projectId == +id);

    this.isMapHidden = false;
  }

  public OnDivisionChange(id: string) {
    // enable controls
    this.reactiveForm.controls['inspectionType'].enable();
    this.reactiveForm.controls['RFInumber'].enable();
    this.reactiveForm.controls['description'].enable();

    // enable inspection types
    this.inspectionTypes = this.scheduleOptions.inspectionTypeModels?.filter(i => i.divisionID == +id);
  }

  public OnSubmit(form: FormGroup) {
    this.submitdisabled = true;
    let someDateStart = this.reactiveForm.value.inspectionDate;

    console.log("StartTime: " + this.StartTime);
    console.log("EndTime: " + this.EndTime);

    someDateStart.setUTCHours( (new Date(this.StartTime)).getUTCHours()-1);
    someDateStart.setUTCMinutes( (new Date(this.StartTime)).getUTCMinutes());
    someDateStart.setSeconds(0);
    console.log("someDateStart: " + someDateStart);

    let someDateEnd = new Date(this.reactiveForm.value.inspectionDate);
    someDateEnd.setUTCHours( (new Date(this.EndTime)).getUTCHours()-1);
    someDateEnd.setMinutes( (new Date(this.EndTime)).getMinutes());
    someDateEnd.setSeconds(0);
    console.log("someDateEnd: " + someDateEnd);
    
    // build post body
    let vm: PostInspectionRequestBody = {
      inspectionDateStart: this.getLocalTime(someDateStart),
      inspectionDateEnd: this.getLocalTime(someDateEnd),
      projectId: this.reactiveForm.value.project,
      tradePartnerId: this.reactiveForm.value.company,
      siteLocationId: this.reactiveForm.value.siteLocation,
      inspectionTypeId: this.reactiveForm.value.inspectionType,
      submittalNumber: this.reactiveForm.value.RFInumber ? this.reactiveForm.value.RFInumber : 'none',
      userId: (this.userModel.objectId?.toString() !== undefined)?this.userModel.objectId?.toString():"", //TO DO: have to define user and plug id in here
      description: this.reactiveForm.value.description
    };

    // post to API
    
    this.applicationApiService.apiInspectionPostInspection(vm)
      .subscribe((body: number) => {
        window.location.href = "./inspection/search";
      });
    
  }

  public getLocalTime(d: Date)
  {
    let someDateString = (d.getMonth()+1) + '/' +
      d.getDate() + '/' +
      d.getFullYear() + ' ' +
      d.getHours() + ":" +
      d.getMinutes() + ":" +
      d.getSeconds() + ' UTC';

    return new Date(Date.parse(someDateString));
  }

  public convertDate(t: string)
  {
    let hour = new Date(+t).toTimeString().split(" ")[0].split(":")[0];
    let meridian = "AM"


    if (+hour >= 13)
    {
      hour = (+hour - 12).toString();
      meridian = "PM";
    }
    else if (+hour == 12)
    {
      hour = (+hour).toString();
      meridian = "PM";
    }
    else
    {
      hour = (+hour).toString();
    }

    return hour
    + ":"
    + new Date(+t).toTimeString().split(" ")[0].split(":")[1]
    + " " + meridian;
  }
}
