<div *ngIf="userProfile">
    <div class="outer-container">
      <div [cdkMenuTriggerFor]="profile" #menuTrigger>
        <div *ngIf="!hasProfilePhoto" class="nophoto-profile-icon">
            <span class="nophoto-profile-text-link">{{userInitials}}</span>
        </div>
        <div *ngIf="hasProfilePhoto" style="padding-top: 2px;">
            <img [src]="userProfilePhotoUrl" class="profile-photo-icon" />
        </div>
      </div>
    </div>
    <ng-template #profile>
      <div class="profile-menu-rows mat-elevation-z10">
        <div class="profile-menu-row">
          <div class="profile-menu-cols">
            <div class="profile-menu-column" style="min-width: 100px;">
              <div *ngIf="!hasProfilePhoto">
                <div class="nophoto-profile-icon-large">
                  <mat-icon>badge</mat-icon>
                </div>
              </div>
              <div *ngIf="hasProfilePhoto">
                  <img [src]="userProfilePhotoUrl" class="profile-photo" />
              </div>
            </div>
            <div class="profile-menu-column" style="min-width: 240px">
              <div style="font-size:large"><strong>{{displayName}}</strong></div>
              <div style="font-size:medium" *ngIf="roleToDisplay"><strong>{{roleToDisplay}}</strong></div>
              <div>{{userProfile.emailAddress}}</div>
              <div>{{userProfile.jobTitle}}</div>
              <div>{{userProfile.officeLocation}}</div>
            </div>
          </div>
        </div>
        <div class="profile-menu-row">
          <button mat-raised-button (click)="LogoutButtonClicked()" color="primary">Logout</button>
        </div>
      </div>
    </ng-template>
</div>
<div *ngIf="!userProfile">
    <div class="outer-container">
        <div class="nophoto-profile-icon">
            <span class="nophoto-profile-text-link">ME</span>
        </div>
    </div>
</div>
