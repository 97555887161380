import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
    title: string;
    content: string;
}

@Component({
    selector: 'infobox',
    templateUrl: './infobox.component.html',
    styleUrls: ['./infobox.component.scss']
})

export class InfoboxComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<InfoboxComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) { }

    ngOnInit() {
        let dateNow = new Date();
        console.log('Infobox OnInit started at: ' + dateNow);


        dateNow = new Date();
        console.log('Infobox OnInit completed at: ' + dateNow);
    }

    OkClick(): void {
        this.dialogRef.close(true);
    }
}
