import {Injectable} from '@angular/core';
import { ApplicationApiService, Feature, UserSearchViewModel } from '../../services/application-api.service';
import {BehaviorSubject, Observable, Subscription, timer} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class B2cService {
    private _refreshTimer = 60000;
    private featuresSubscription: Subscription = new Subscription();
    public featuresList$: BehaviorSubject<UserSearchViewModel> = new BehaviorSubject<UserSearchViewModel>({});
    public initialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private _featuresClient: ApplicationApiService) {
    }

    public featureEnabled(featureName: string): boolean {
        /*let enabled: boolean;
        const list = this.featuresList$.getValue();
        enabled = list.some(f => f.name && f.name.toLowerCase() === featureName.toLowerCase());
        return enabled;*/
        return true;
    }

    public initFeatureManagement() {
        const timer$ = timer(0, this._refreshTimer).pipe(tap(_ => {
            console.log('Feature Timer Called');
        }));

        this.featuresSubscription = timer$
            .pipe(switchMap(_ => this._featuresClient.apiUserGetUser()))
            .pipe(tap((features: UserSearchViewModel) => {

                this.featuresList$.next(features || []);
                this.initialized$.next(true);
            })).subscribe();
    }

    hasAllFeaturesEnabled(...features: string[]): boolean {
        /*
        if (!this.featuresSubscription) {
            return false;
        }
        if (!features?.length) {
            return false;
        }
        features = features.map(f => f.toLowerCase());
        const featureList = this.featuresList$.getValue();
        if (!featureList?.length) {
            return false;
        }
        const enabledFeatures = featureList.filter(f => f.enabled === true).map(f => f?.name?.toLowerCase());
        if (enabledFeatures.length === 0)
            return false;
        let hasAll: boolean;
        hasAll = features.every(f => enabledFeatures.includes(f));

        return hasAll;
        */
        console.log("did we get here");

        return (this.featuresList$.value != null);
    }
}
