import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Route, UrlSegment, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, filter } from 'rxjs/operators';
import { B2cService } from './b2c.service';

@Injectable({
  providedIn: 'root'
})
export class B2cGuard  {
  constructor(private readonly featureService: B2cService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log("canActivate");
    return this.hasFeatures(route.data);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasFeatures(route.data);
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.hasFeatures(route.data)
  }

  private hasFeatures(data: any) {
    const features = data?.['features'] as string[];
    if (!features) {
      return true;
    }

    return this.featureService.initialized$
      .pipe(filter(init => !!init))
      .pipe(switchMap(() => {
        return of(this.featureService.hasAllFeaturesEnabled(...features));
      }));
  }
}