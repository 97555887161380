<div [hidden]="!isLoading">
  <div class="d-flex flex-column align-items-center">
    <div class="p-2">
        <h2>Loading Form...</h2>
        <div>Please wait...</div>
    </div>
    <div class="p-2">
        <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
<div [hidden]="isLoading">


<mat-card class="p-3">
  <mat-tab-group>
    <mat-tab class="scrollbar" label="DELIVERY SCHEDULE">
      <app-calendar></app-calendar>
    </mat-tab>
    <mat-tab label="INSPECTION SCHEDULE">
      <app-calendarinspection></app-calendarinspection>
    </mat-tab>
    <mat-tab label="INSPECTION ACTION ITEMS">
      <div class="p-3">
        <app-inspection-search-grid [inspections]="inspections" [userRole]=userModel.role></app-inspection-search-grid>
      </div>
    </mat-tab>
    <mat-tab style="height: 600px;" label="DELIVERY ACTION ITEMS">
      <div class="p-3">
        <app-delivery-search-grid [deliveries]="deliveries" [userRole]=userModel.role></app-delivery-search-grid>
      </div>
    </mat-tab>
    <!--
    <mat-tab style="height: 600px;" label="CALENDAR (BETA)">
      <div class="p-3"> 
        <app-calendarinspection></app-calendarinspection>
      </div>
    </mat-tab>
    -->
    
    
  </mat-tab-group>
</mat-card>
</div>
