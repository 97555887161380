<div [hidden]="!isLoading">
  <div class="d-flex flex-column align-items-center">
    <div class="p-2">
        <h2>Loading Form...</h2>
        <div>Please wait...</div>
    </div>
    <div class="p-2">
        <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
<div [hidden]="isLoading">
  <mat-card class="p-3">
    <h2 style="color:#c34d28;">Schedule a Delivery&nbsp;<mat-icon style="vertical-align: sub; margin-left: 5px; margin-right: 5px; font-size: 26px">local_shipping</mat-icon></h2>
    <form [formGroup]="reactiveForm" (ngSubmit)="OnSubmit(reactiveForm)">
      <div class="row">
        <div class="col-12 col-lg-3 col-md-5">
          <mat-form-field>
            <mat-label class="delivery">Delivery Date</mat-label>
            <input matInput  formControlName="deliveryDate" [matDatepicker]="DeliveryDate">
            <mat-datepicker-toggle matIconSuffix [for]="DeliveryDate"></mat-datepicker-toggle>
            <mat-datepicker #DeliveryDate></mat-datepicker>
            <mat-error *ngIf="f['deliveryDate'].errors?.['required']">
                Delivery Date is Required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-3 col-md-5">
          <ngx-mat-timepicker-field class="delivery" style="width:100%" color="warn" [format]="12" formControlName="deliveryTime"></ngx-mat-timepicker-field>
          <mat-error *ngIf="f['deliveryTime'].errors?.['required']">
            Delivery Time is Required
          </mat-error>
        </div>
        <div class="col-12 col-lg-3 col-md-5">
          <mat-form-field style="width:50%">
            <mat-label class="delivery" >Time Slot</mat-label>
            <mat-select (selectionChange)="OnProjectChange($event.value)" formControlName="deliveryTimeSlot">
              <mat-option *ngFor="let timeSlot of timeSlots" value="{{timeSlot.id}}">
                {{timeSlot.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="f['deliveryTimeSlot'].errors?.['required']">
              Time Slot is Required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-12 col-lg-3 col-md-5">
          <mat-form-field style="width:100%">
            <mat-label>Project</mat-label>
            <mat-select (selectionChange)="OnProjectChange($event.value)" formControlName="project">
              <mat-option *ngFor="let project of projects" value="{{project.id}}">
                {{project.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="f['project'].errors?.['required']">
              Project is Required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-12 col-lg-3 col-md-5">
          <mat-form-field style="width:100%">
            <mat-label>Company</mat-label>
            <mat-select formControlName="company">
              <mat-option *ngFor="let company of companies" value="{{company.id}}">
                {{company.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="f['company'].errors?.['required']">
              Company is Required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-3 col-md-5">
          <mat-form-field style="width:100%">
            <mat-label>Site Location</mat-label>
            <mat-select formControlName="siteLocation">
              <mat-option *ngFor="let location of siteLocations" value={{location.id}}>
                {{location.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="f['siteLocation'].errors?.['required']">
            Site Location is Required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-12 col-lg-6 col-md-12">
          <mat-form-field style="width:100%">
            <mat-label>Delivery Description</mat-label>
            <textarea matInput formControlName="description"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-12">
          <button [disabled]="submitdisabled" mat-raised-button type="submit" color="warn" [disabled]="reactiveForm.invalid">Submit Delivery Request</button>
        </div>
      </div>
      <!-- <div [hidden]="isMapHidden" class="row">
        <img src="{{appConfig.azureBlobStorageURL}}{{reactiveForm.value.project}}">
      </div> -->
    </form>
  </mat-card>
</div>
