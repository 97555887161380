import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { UserStoreService } from '../../services/user-store/user-store.service';

@Component({
    selector: 'app-authentication',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

    constructor(
        public userProfileStore: UserStoreService,
        //public oidcSecurityService: OidcSecurityService
    ) { }

    ngOnInit() {
        let dateNow = new Date();
        console.log('AuthComponent OnInit started at: ' + dateNow);

        //this.oidcSecurityService.checkAuth().subscribe(() => this.oidcSecurityService.authorize());

        dateNow = new Date();
        console.log('AuthComponent OnInit completed at: ' + dateNow);
    }
}
