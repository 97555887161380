import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ApproveDeliveryModalComponent } from '../approve-delivery-modal/approve-delivery-modal.component';
import { UpdateDeliveryModalComponent } from '../update-delivery-modal/update-delivery-modal.component';
import { CancelDeliveryModalComponent } from '../cancel-delivery-modal/cancel-delivery-modal.component';
import { ConfirmDeliveryModalComponent } from '../confirm-delivery-modal/confirm-delivery-modal.component';
import { RescheduleDeliveryModalComponent } from '../reschedule-delivery-modal/reschedule-delivery-modal.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeliverySearchViewModel } from 'src/app/core/services/application-api.service';

@Component({
  selector: 'app-delivery-search-grid',
  templateUrl: './delivery-search-grid.component.html',
  styleUrls: ['./delivery-search-grid.component.scss']
})
export class DeliverySearchGridComponent {
  public displayedColumns: string[] = ['id','status','company','deliveryDate','time','description','siteLocation','requestedBy','visitOptions','actions'];
  @Input() public userRole: string | null | undefined = "";
  @Input() public deliveries: DeliverySearchViewModel[] = [];
  public deliveriesTable: MatTableDataSource<DeliverySearchViewModel> = new MatTableDataSource<DeliverySearchViewModel>();
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  constructor(
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,
  )
  { }

  ngOnChanges(): void {
    console.log("Deliveries Count: " + this.deliveries.length);
    this.deliveriesTable = new MatTableDataSource<DeliverySearchViewModel>(this.deliveries);
    this.deliveriesTable.paginator = this.paginator;
    this.deliveriesTable.sort = this.sort;
  }

  public CanApproveVisit(status: string): boolean {
    if(status != "Requested")
      return false;
    else if (this.userRole == "SuperUser" || this.userRole == "QA" || this.userRole == "Super")
    {
      return true;
    }

    return false;
  }

  public CanRescheduleVisit(status: string): boolean {
    if(status != "Requested" && status != "Scheduled")
      return false;
    else if (this.userRole == "SuperUser" || this.userRole == "Trade Partner")
    {
      return true;
    }
    return false;
  }

  public CanDeliver(status: string): boolean {
    if(status != "Scheduled")
      return false;
    else if (this.userRole == "SuperUser" || this.userRole == "QA" || this.userRole == "Super")
    {
      return true;
    }

    return false;
  }

  public CanCancelDelivery(status: string): boolean {
    return (status != "Canceled" && status != "Delivered");
  }

  public CanEditDelivery(status: string): boolean {
    return (status != "Canceled" && status != "Delivered");
  }

  public ApproveInspectionPopup(delivery: DeliverySearchViewModel) {
    let dialogRef = this.matDialog.open(ApproveDeliveryModalComponent, {
      minHeight: '325px',
      width: '450px',
      data: delivery
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result.event == 'Approve') {
        this.ShowSnackBar('Delivery Approved Successfully', 'Ok');
      }
    });
  }

  public UpdateDeliveryPopup(delivery: DeliverySearchViewModel) {
    let dialogRef = this.matDialog.open(UpdateDeliveryModalComponent, {
      minHeight: '325px',
      width: '325px',
      data: delivery
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result.event == 'Update') {
        this.ShowSnackBar('Delivery Updated Successfully', 'Ok');
      }
    });
  }

  public RescheduleDeliveryPopup(delivery: DeliverySearchViewModel) {
    let dialogRef = this.matDialog.open(RescheduleDeliveryModalComponent, {
      minHeight: '325px',
      width: '325px',
      data: delivery
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result.event == 'Reschedule') {
        this.ShowSnackBar('Delivery Rescheduled Successfully', 'Ok');
      }
    });
  }

  public ConfirmDeliveryPopup(delivery: DeliverySearchViewModel) {
    let dialogRef = this.matDialog.open(ConfirmDeliveryModalComponent, {
      minHeight: '325px',
      width: '450px',
      data: delivery
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result.event == 'Confirm') {
        this.ShowSnackBar('Delivery Confirmed Successfully', 'Ok');
      }
    });
  }

  public CancelDeliveryPopup(delivery: DeliverySearchViewModel) {
    let dialogRef = this.matDialog.open(CancelDeliveryModalComponent, {
      minHeight: '325px',
      width: '450px',
      data: delivery
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result.event == 'Cancel') {
        this.ShowSnackBar('Delivery Cancelled Successfully', 'Ok');
      }
    });
  }

  public ShowSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000
    });
  }
}
