import { ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent} from './app.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FooterComponent } from './core/components/footer/footer.component';
import { FeatureModule } from './core/features/feature.module';
import { UnauthorizedComponent } from './routes/unauthorized/unauthorized.component';
import { FlexLayoutModule } from "@angular/flex-layout";
import { AvatarComponent } from './core/components/avatar/avatar.component';
import { DownloadDialog } from './core/components/download-dialog/download-dialog';
import { InfoboxComponent } from './core/components/infobox/infobox.component';
import { AuthComponent } from './core/components/auth/auth.component';
import { ApplicationInsightsErrorHandler } from './core/services/app-insights.errorhandler';
import { SearchDeliveryComponent } from './routes/delivery/search-delivery/search-delivery.component';
import { MatTabsModule } from '@angular/material/tabs';
import { AddDeliveryComponent } from './routes/delivery/add-delivery/add-delivery.component';
import { InspectionScheduleComponent } from './routes/inspection/schedule/schedule.component';
import { InspectionSearchComponent } from './routes/inspection/inspection-search/inspection-search.component';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';
import { IntlModule } from "@progress/kendo-angular-intl";
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';

import { loginRequest } from './auth-config';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import {
    MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService,
    MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent, ProtectedResourceScopes
} from '@azure/msal-angular';
import { TodoViewComponent } from './routes/todo-view/todo-view.component';
import { HomeComponent } from './routes/home/home.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { FormFieldModule, InputsModule } from '@progress/kendo-angular-inputs';
//import { LabelModule } from "@progress/kendo-angular-label";
import { MatTreeModule, MatNestedTreeNode } from '@angular/material/tree';
import { ApproveInspectionModalComponent } from './routes/inspection/inspection-search/approve-inspection-modal/approve-inspection-modal.component';
import { FailInspectionModalComponent } from './routes/inspection/inspection-search/fail-inspection-modal/fail-inspection-modal.component';
import { UpdateInspectionModalComponent } from './routes/inspection/inspection-search/update-inspection-modal/update-inspection-modal.component';
import { CancelInspectionModalComponent } from './routes/inspection/inspection-search/cancel-inspection-modal/cancel-inspection-modal.component';
import { AdminApproveInspectionModalComponent } from './routes/inspection/inspection-search/admin-approve-inspection-modal/admin-approve-inspection-modal.component';
import { AdminFailInspectionModalComponent } from './routes/inspection/inspection-search/admin-fail-inspection-modal/admin-fail-inspection-modal.component';
import { ApproveVisitModalComponent } from './routes/inspection/inspection-search/approve-visit-modal/approve-visit-modal.component';
import { DenyVisitModalComponent } from './routes/inspection/inspection-search/deny-visit-modal/deny-visit-modal.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { IconsModule } from '@progress/kendo-angular-icons';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ApproveDeliveryModalComponent } from './routes/delivery/search-delivery/approve-delivery-modal/approve-delivery-modal.component';
import { UpdateDeliveryModalComponent } from './routes/delivery/search-delivery/update-delivery-modal/update-delivery-modal.component';
import { RescheduleDeliveryModalComponent } from './routes/delivery/search-delivery/reschedule-delivery-modal/reschedule-delivery-modal.component';
import { ConfirmDeliveryModalComponent } from './routes/delivery/search-delivery/confirm-delivery-modal/confirm-delivery-modal.component';
import { CancelDeliveryModalComponent } from './routes/delivery/search-delivery/cancel-delivery-modal/cancel-delivery-modal.component';
import { DeliverySearchGridComponent } from './routes/delivery/search-delivery/delivery-search-grid/delivery-search-grid.component';
import { InspectionSearchGridComponent } from './routes/inspection/inspection-search/inspection-search-grid/inspection-search-grid.component';
import { appConfiguration } from './core/configuration/app-configuration';
import { BrowserModule } from '@angular/platform-browser';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { B2cModule } from './core/auth/b2c/b2c.module';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DeliverycalendarComponent } from './routes/home/deliverycalendar/deliverycalendar.component';
import { InspectioncalendarComponent } from './routes/home/inspectioncalendar/inspectioncalendar.component';
import { CalendarComponent } from './routes/home/calendar/calendar.component';
import { DeliveryListComponent } from './routes/home/calendar/delivery-list/delivery-list.component';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ListViewModule } from '@progress/kendo-angular-listview';
import {MatSliderModule} from '@angular/material/slider';
import { CalendarinspectionComponent } from './routes/home/calendarinspection/calendarinspection.component';

/* Get Config variables */
const request = new XMLHttpRequest();
request.open("GET", "/assets/configuration/app-configuration.json", false); // `false` makes the request synchronous
request.send(null);
let Url: string = "";
let resourceAppID: string = "";
let clientID: string = "";
let authAuthority: string = "";
let knownAuthorities: string = "";
let scopeRead: string = "";
let scopeReadWrite: string = "";
if (request.status === 200) {
  console.log("request.responseText: " + request.responseText);
  Url =              request.responseText.split('"apiRootUrl": "')[1].split('"')[0];
  resourceAppID =    request.responseText.split('"resourceAppID": "')[1].split('"')[0];
  clientID =         request.responseText.split('"clientId": "')[1].split('"')[0];
  authAuthority =    request.responseText.split('"authAuthority": "')[1].split('"')[0];
  knownAuthorities = request.responseText.split('"knownAuthority": "')[1].split('"')[0];
  scopeRead =        request.responseText.split('"scopeRead": "')[1].split('"')[0];
  scopeReadWrite =   request.responseText.split('"scopeReadWrite": "')[1].split('"')[0];
  console.log("knownAuthorities: " + knownAuthorities);
}

const apiEndpoint = Url;

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
			clientId: clientID,
			authority: authAuthority,
			knownAuthorities: [knownAuthorities],
			redirectUri: "/auth",
			postLogoutRedirectUri: "/"
		},
    cache: {
			cacheLocation: "localStorage",
			storeAuthStateInCookie: false
		},
    system: {
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
                console.log(message);
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false
        }
    }
  });
}

/**
 * MSAL Angular will automatically retrieve tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

  protectedResourceMap.set(apiEndpoint + "/api",[
    {
      httpMethod: 'GET',
      scopes: [scopeRead]
    },
    {
      httpMethod: 'POST',
      scopes: [scopeReadWrite]
    },
    {
      httpMethod: 'PUT',
      scopes: [scopeReadWrite]
    },
    {
      httpMethod: 'DELETE',
      scopes: [scopeReadWrite]
    }
  ]);


  return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap,
  };
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
      interactionType: InteractionType.Redirect,
      authRequest: loginRequest
  };
}


@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    UnauthorizedComponent,
    AvatarComponent,
    DownloadDialog,
    InfoboxComponent,
    AuthComponent,
    SearchDeliveryComponent,
    AddDeliveryComponent,
    InspectionScheduleComponent,
    InspectionSearchComponent,
    TodoViewComponent,
    HomeComponent,
    ApproveInspectionModalComponent,
    FailInspectionModalComponent,
    UpdateInspectionModalComponent,
    CancelInspectionModalComponent,
    AdminApproveInspectionModalComponent,
    AdminFailInspectionModalComponent,
    ApproveVisitModalComponent,
    DenyVisitModalComponent,
    ApproveDeliveryModalComponent,
    UpdateDeliveryModalComponent,
    RescheduleDeliveryModalComponent,
    ConfirmDeliveryModalComponent,
    CancelDeliveryModalComponent,
    DeliverySearchGridComponent,
    InspectionSearchGridComponent,
    DeliverycalendarComponent,
    InspectioncalendarComponent,
    CalendarComponent,
    DeliveryListComponent,
    CalendarinspectionComponent,
  ],
  imports: [
    BrowserModule,
    FeatureModule,
    B2cModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule.forRoot(),
    //AuthConfigModule,
    FlexLayoutModule,
    MatTabsModule,
    MsalModule,
    SchedulerModule,
    IntlModule,
    CdkAccordionModule,
    InputsModule,
    //LabelModule,
    MatTreeModule,
    DateInputsModule,
    IconsModule,
    FormFieldModule,
    ButtonsModule,
    NgxMatTimepickerModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    DropDownsModule,
    LayoutModule,
    ListViewModule,
    MatSliderModule,
  ],
  providers: [
    /*{
      provide: ErrorHandler,
      useClass: ApplicationInsightsErrorHandler
    },*/
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
    },
    {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory
    },
    {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
