/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

const request = new XMLHttpRequest();
request.open("GET", "/assets/configuration/app-configuration.json", false); // `false` makes the request synchronous
request.send(null);
let Url: string = "";
let resourceAppID: string = "";
if (request.status === 200) {
  console.log("request.responseText: " + request.responseText);
  Url = request.responseText.split('"apiRootUrl": "')[1].split('"')[0];
  resourceAppID = request.responseText.split('"resourceAppID": "')[1].split('"')[0];
  console.log("resourceAppID: " + resourceAppID);
}

const apiEndpoint = Url;


/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
 export const protectedResources = {
  apiTodoList: {
      endpoint: apiEndpoint + "/api/todo/get",
      scopes: {
        read: ["https://turnerappdevelopmentdevqa.onmicrosoft.com/167fe19c-db04-4e79-8de0-9b4d10f7796c/ProjectLens.Read"],
        write: ["https://turnerappdevelopmentdevqa.onmicrosoft.com/167fe19c-db04-4e79-8de0-9b4d10f7796c/ProjectLens.ReadWrite"]
      }
  },
  scheduleInspectionOptions: {
      endpoint: apiEndpoint + "/api/inspection/ScheduleInspectionOptions",
      scopes: {
          read: ["https://turnerappdevelopmentdevqa.onmicrosoft.com/167fe19c-db04-4e79-8de0-9b4d10f7796c/ProjectLens.Read"],
          write: ["https://turnerappdevelopmentdevqa.onmicrosoft.com/167fe19c-db04-4e79-8de0-9b4d10f7796c/ProjectLens.ReadWrite"]
      }
  },
  inspectionSearchOptions: {
      endpoint: apiEndpoint + "/api/Inspection/InspectionSearchOptions",
      scopes: {
          read: ["https://turnerappdevelopmentdevqa.onmicrosoft.com/167fe19c-db04-4e79-8de0-9b4d10f7796c/ProjectLens.Read"],
          write: ["https://turnerappdevelopmentdevqa.onmicrosoft.com/167fe19c-db04-4e79-8de0-9b4d10f7796c/ProjectLens.ReadWrite"]
      }
  },
  inspectionSearch: {
      endpoint: apiEndpoint + "/api/Inspection/Search",
      scopes: {
          read: ["https://turnerappdevelopmentdevqa.onmicrosoft.com/167fe19c-db04-4e79-8de0-9b4d10f7796c/ProjectLens.Read"],
          write: ["https://turnerappdevelopmentdevqa.onmicrosoft.com/167fe19c-db04-4e79-8de0-9b4d10f7796c/ProjectLens.ReadWrite"]
      }
  },
  postInspection: {
      endpoint: apiEndpoint + "/api/Inspection/Add",
      scopes: {
          read: ["https://turnerappdevelopmentdevqa.onmicrosoft.com/167fe19c-db04-4e79-8de0-9b4d10f7796c/ProjectLens.Read"],
          write: ["https://turnerappdevelopmentdevqa.onmicrosoft.com/167fe19c-db04-4e79-8de0-9b4d10f7796c/ProjectLens.ReadWrite"]
      }
  },
  getScheduleDeliveryOptions: {
      endpoint: apiEndpoint + "/api/Delivery/ScheduleDeliveryOptions",
      scopes: {
          read: ["https://turnerappdevelopmentdevqa.onmicrosoft.com/167fe19c-db04-4e79-8de0-9b4d10f7796c/ProjectLens.Read"],
          write: ["https://turnerappdevelopmentdevqa.onmicrosoft.com/167fe19c-db04-4e79-8de0-9b4d10f7796c/ProjectLens.ReadWrite"]
      }
  },
  addDelivery: {
      endpoint: apiEndpoint + "/api/Delivery",
      scopes: {
          read: ["https://turnerappdevelopmentdevqa.onmicrosoft.com/167fe19c-db04-4e79-8de0-9b4d10f7796c/ProjectLens.Read"],
          write: ["https://turnerappdevelopmentdevqa.onmicrosoft.com/167fe19c-db04-4e79-8de0-9b4d10f7796c/ProjectLens.ReadWrite"]
      }
  },
  getSearchDeliveryOptions: {
      endpoint: apiEndpoint + "/api/Delivery/SearchDeliveryOptions",
      scopes: {
          read: ["https://turnerappdevelopmentdevqa.onmicrosoft.com/167fe19c-db04-4e79-8de0-9b4d10f7796c/ProjectLens.Read"],
          write: ["https://turnerappdevelopmentdevqa.onmicrosoft.com/167fe19c-db04-4e79-8de0-9b4d10f7796c/ProjectLens.ReadWrite"]
      }
  },
  getDeliveries: {
      endpoint: apiEndpoint + "/api/Delivery/SearchDelivery",
      scopes: {
          read: ["https://turnerappdevelopmentdevqa.onmicrosoft.com/167fe19c-db04-4e79-8de0-9b4d10f7796c/ProjectLens.Read"],
          write: ["https://turnerappdevelopmentdevqa.onmicrosoft.com/167fe19c-db04-4e79-8de0-9b4d10f7796c/ProjectLens.ReadWrite"]
      }
  },
  getSiteLocation: {
      endpoint: apiEndpoint + "/api/SiteLocation",
      scopes: {
          read: ["https://turnerappdevelopmentdevqa.onmicrosoft.com/167fe19c-db04-4e79-8de0-9b4d10f7796c/ProjectLens.Read"],
          write: ["https://turnerappdevelopmentdevqa.onmicrosoft.com/167fe19c-db04-4e79-8de0-9b4d10f7796c/ProjectLens.ReadWrite"]
      }
  },
  getTradePartners: {
      endpoint: apiEndpoint + "/api/TradePartner",
      scopes: {
          read: ["https://turnerappdevelopmentdevqa.onmicrosoft.com/167fe19c-db04-4e79-8de0-9b4d10f7796c/ProjectLens.Read"],
          write: ["https://turnerappdevelopmentdevqa.onmicrosoft.com/167fe19c-db04-4e79-8de0-9b4d10f7796c/ProjectLens.ReadWrite"]
      }
  },
  addTradePartners: {
      endpoint: apiEndpoint + "/api/TradePartner",
      scopes: {
          read: ["https://turnerappdevelopmentdevqa.onmicrosoft.com/167fe19c-db04-4e79-8de0-9b4d10f7796c/ProjectLens.Read"],
          write: ["https://turnerappdevelopmentdevqa.onmicrosoft.com/167fe19c-db04-4e79-8de0-9b4d10f7796c/ProjectLens.ReadWrite"]
      }
  },
  getUser: {
      endpoint: apiEndpoint + "/api/User",
      scopes: {
          read: ["https://turnerappdevelopmentdevqa.onmicrosoft.com/167fe19c-db04-4e79-8de0-9b4d10f7796c/ProjectLens.Read"]
      }
  },

}

/**
* Scopes you add here will be prompted for user consent during sign-in.
* By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
* For more information about OIDC scopes, visit:
* https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
*/
export const loginRequest = {
  scopes: []
};
