import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { faIdCard } from '@fortawesome/free-regular-svg-icons';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { User } from '../../services/user-store/user';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { UserStoreService } from '../../services/user-store/user-store.service';
import { CommonFunctionsService } from '../../services/common-functions';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit, OnDestroy {
    public userInitials: string = 'ME';
    private userProfileSubscription: Subscription | undefined;
    public userProfilePhotoUrl: SafeUrl | undefined;
    public roleToDisplay: string = '';
    public displayName= '';
    public userProfile: User | undefined;
    public hasProfilePhoto: boolean = false;
    private userDataSubscription: Subscription| undefined;

  // initialize fa
  public faIdCard = faIdCard;

  constructor(
    private domSanitizer: DomSanitizer,
    private userProfileStore: UserStoreService,
    //private oidcSecurityService: OidcSecurityService,
    private commonFunctions: CommonFunctionsService
  ) { }

    ngOnInit(): void {
        let dateNow = new Date();
        console.log('UserProfileComponent ngOnInit started at: ' + dateNow);

        //user info from OIDC Service
        // this.userDataSubscription = this.oidcSecurityService.userData$.subscribe(data => {
        //     if (data) {
        //         this.roleToDisplay = this.commonFunctions.GetApplicationRoleTextFromOIDCUserData(data);
        //     }
        // });

        // user info from  Graph
        this.userProfileSubscription = this.userProfileStore.userObservable$.subscribe(data => {
            if (data) {
                this.userProfile = data;
                console.log('UserProfileComponent - Change In Profile From Graph');
                console.log('Logged in User:', data);
                let firstName = data.firstName ?? "M";
                let lastName = data.lastName ?? "E";
                this.displayName = firstName + ' ' + lastName;
                this.userInitials = firstName.charAt(0) + lastName.charAt(0);
                if(data.photo) {
                    this.userProfilePhotoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + data.photo);
                    this.hasProfilePhoto = true;
                }
                else {
                    this.hasProfilePhoto = false;
                }
            }
        });

        dateNow = new Date();
        console.log('UserProfileComponent ngOnInit completed at: ' + dateNow);
    }

    ngOnDestroy() {
        if (this.userProfileSubscription) {
            this.userProfileSubscription.unsubscribe();
        }
        if (this.userDataSubscription) {
          this.userDataSubscription.unsubscribe();
      }
    }

    public LogoutButtonClicked = (): void => {
        let dateNow = new Date();
        console.log('UserMenuComponent LogoutButtonClicked started at: ' + dateNow);

        //this.oidcSecurityService.logoff();
    }
}
