import { Component } from '@angular/core';

@Component({
    selector: 'download-dialog',
    templateUrl: 'download-dialog.html',
    styleUrls: ['./download-dialog.scss']
})
export class DownloadDialog {
    constructor(

    ) { }
}
