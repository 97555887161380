import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { ApplicationInsightsService } from '../application-insights.service';
import { ApplicationApiService } from '../application-api.service';
import { User } from './user';

@Injectable({
  providedIn: 'root',
})
export class UserStoreService {
  private user: User | null = null;
  private _userObservable$: BehaviorSubject<User | null>;
  private _authServiceErrorObservable$: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);

  public gettingUser = false;
  public gettingUserFailed: boolean = false;

  constructor(
    private client: ApplicationApiService,
    // private router: Router,
      private applicationInsightsService: ApplicationInsightsService
  ) {
    this._userObservable$ = new BehaviorSubject<User | null>(null);
  }

  // allows components to subscribe to the observable
  public get userObservable$() {
    return this._userObservable$.asObservable();
  }
  public get userBehaviorSubject$() {
    return this._userObservable$;
  }
  public get authErrorObservable$() {
    return this._authServiceErrorObservable$.asObservable();
  }
  // method to provide user object to consumers of the store as a promise
  public GetUserObservable = (): Observable<User | null> => {
    let dateNow = new Date();
    console.log('UserStore GetUserObservable called at ' + dateNow);
    return this._userObservable$.asObservable();
  };

  /*
  //method to get user from service and put it in the store
  public InitializeUser = () => {
    let dateNow = new Date();
    console.log('UserStore InitializeUser called at: ' + dateNow);

    if (!this.gettingUser && !this.user) {
      this.gettingUser = true;
      console.log('UserStore GetStaffUserFromApi called at ' + dateNow);

      this.client
        .apiUserProfileGetUserProfile()
        .pipe(take(1))
        .subscribe(
          (data: UserProfileDTO) => {
            if (data) {
              this.user = {
                userInitials: this.FixInitials(data),
                isApplicationAdmin: data.isApplicationAdmin as boolean,
                idpUserId: data.idpUserId as string,
                userPrincipalName: data.userPrincipalName as string,
                displayName: data.displayName as string,
                firstName: data.firstName as string,
                lastName: data.lastName as string,
                emailAddress: data.emailAddress as string,
                jobTitle: data.jobTitle as string,
                companyName: data.companyName as string,
                officeLocation: data.officeLocation as string,
                officeAddress: data.officeAddress as string,
                officeCity: data.officeCity as string,
                officeState: data.officeState as string,
                officePostalCode: data.officePostalCode as string,
                officeCountryOrRegion: data.officeCountryOrRegion as string,
                departmentNumber: data.departmentNumber as string,
                photo: data.photo as string
              };
               this.GetUserFromApiSuccess();
            } else {
              this.GetUserFromApiFailed();
            }
          },
          (error: any) => {
            this.GetUserFromApiFailed();
          }
        );
    }
  };
  */
  // allows components to reset the observable
  public ResetUser = (): void => {
    let dateNow = new Date();
    console.log('UserStore ResetUser called at: ' + dateNow);

    if (this._userObservable$) {
      console.log('User Observable new value: ', this.user);
      this._userObservable$.next(this.user);
    }

    dateNow = new Date();
    console.log('UserStore ResetUser completed at: ' + dateNow);
  };

  private GetUserFromApiSuccess = (): void => {
    console.log('GetUserFromApi succeeded. this.appUser: ', this.user);

    if (this.user?.idpUserId) {
      this.applicationInsightsService.setUserId(this.user.idpUserId);
      //appInsights.setAuthenticatedUserContext(this.appUser.id.toString(), this.appUser.email);
    }

    this.ResetUser();

    this.gettingUser = false;
    this.gettingUserFailed = false;

    let dateNow = new Date();
    console.log(
      'UserStore GetUserFromAuthService completed at ' +
        dateNow +
        ' without errors'
    );
  };
  private GetUserFromApiFailed = (errMsg?: string): void => {
    this.gettingUser = false;
    this.gettingUserFailed = true;

    if (errMsg) {
      this._authServiceErrorObservable$.next(errMsg);
    }

    this.ResetUser();

    let dateNow = new Date();
    console.log('UserStore GetUserFromApi failed at ' + dateNow);
  };

  /*
  private FixInitials = (
    userProfile: UserProfileDTO
  ) => {
    let firstInitial: string = userProfile.firstName ? userProfile.firstName.slice(0, 1) : '';
    let lastInitial: string = userProfile.lastName ? userProfile.lastName.slice(0, 1) : '';

    return firstInitial + lastInitial;
  };
  */
}
