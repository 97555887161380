<div>
  <mat-toolbar class="fix-nav noscrollbar">
    <mat-toolbar-row>
      <button mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm>
        <mat-icon>menu</mat-icon>
      </button>
      <div class="nav-brand">
        <img src="./assets/images/turner-logo.png">
      </div>
      <span class="app-title">ProjectLens</span>
      <span class="menu-spacer"></span>
      <div fxShow="true" fxHide.lt-md style="margin-top: 10px;">
        <!-- The following menu items will be hidden on both SM and XS screen sizes -->
        <a [routerLink]="['home']" mat-button class="header-link">Home</a>

        <button mat-button class="header-link" [matMenuTriggerFor]="belowMenu">Inspection</button>
        <mat-menu #belowMenu="matMenu" yPosition="below">
          <a [routerLink]="['inspection', 'schedule']" mat-menu-item class="child-link">Schedule Inspection</a>
          <a [routerLink]="['inspection', 'search']" mat-menu-item class="child-link">Search Inspections</a>
        </mat-menu>
        <button [hidden]="cantSeeDelivery" mat-button class="header-link" [matMenuTriggerFor]="belowMenuDelivery">Delivery</button>
        <mat-menu #belowMenuDelivery="matMenu" yPosition="below">
          <a [routerLink]="['delivery', 'add']" mat-menu-item class="child-link">Schedule Delivery</a>
          <a [routerLink]="['delivery', 'search']" mat-menu-item class="child-link">Search Deliveries</a>
        </mat-menu>
        <button [hidden]="cantSeeAdminFeatures" mat-button class="header-link" [matMenuTriggerFor]="belowAdminMenu">Administration</button>
        <mat-menu #belowAdminMenu="matMenu" yPosition="below">
          <a [routerLink]="['project']" mat-menu-item class="child-link">Project Settings</a>
          <a [routerLink]="['users']" mat-menu-item class="child-link">Users</a>
        </mat-menu>

        <button mat-button class="header-link" *ngIf="!loginDisplay" (click)="login()">Login</button>
        <button mat-button class="header-link" *ngIf="loginDisplay" (click)="logout()">Logout</button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-sidenav-container fxFlexFill>
    <mat-sidenav #sidenav>
      <mat-nav-list>
        <a [routerLink]="['home']" mat-menu-item class="child-link" style="font-weight: 500;">Home</a>
        <div mat-list-item style="font-weight: 500;font-size: 14px;line-height: 48px; margin-left: 15px;">Inspection</div>
        <a [routerLink]="['inspection', 'schedule']" mat-menu-item class="child-link">&nbsp;&nbsp;Schedule Inspection</a>
        <a [routerLink]="['inspection', 'search']" mat-menu-item class="child-link">&nbsp;&nbsp;Search Inspections</a>
        <div [hidden]="cantSeeDelivery" mat-list-item style="font-weight: 500;font-size: 14px;line-height: 48px; margin-left: 15px;">Delivery</div>
        <a [hidden]="cantSeeDelivery" [routerLink]="['delivery', 'add']" mat-menu-item class="child-link">&nbsp;&nbsp;Schedule Delivery</a>
        <a [hidden]="cantSeeDelivery" [routerLink]="['delivery', 'search']" mat-menu-item class="child-link">&nbsp;&nbsp;Search Deliveries</a>
        <div [hidden]="cantSeeAdminFeatures"  mat-list-item style="font-weight: 500;font-size: 14px;line-height: 48px; margin-left: 15px;">Administration</div>
        <a [hidden]="cantSeeAdminFeatures"  [routerLink]="['project']" mat-menu-item class="child-link">&nbsp;&nbsp;Project Settings</a>
        <a [hidden]="cantSeeAdminFeatures"  [routerLink]="['users']" mat-menu-item class="child-link">&nbsp;&nbsp;Users</a>
        <a mat-menu-item class="child-link" *ngIf="!loginDisplay" (click)="login()">Login</a>
        <a mat-menu-item class="child-link" stlye="font-weight: 500;" *ngIf="loginDisplay" (click)="logout()">Logout</a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content fxFlexFill>
      <div class="page-content">
        <router-outlet></router-outlet>
        <br>
        <app-footer></app-footer>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
